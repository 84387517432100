import React, { useCallback, useState, useRef, useEffect } from 'react';
import ZoomVideo, { TestMicrophoneReturn, TestSpeakerReturn } from '@zoom/videosdk';
import { useMount, useUnmount } from '../../hooks';
import './preview.scss';
import MicrophoneButton from '../video/components/microphone';
import CameraButton from '../video/components/camera';
import { message, Button, Progress, Select } from 'antd';
import { MediaDevice } from '../video/video-types';
import classNames from 'classnames';
import Header from '../../Header';
import { HeaderProvider } from '../../HeaderContext';
import apiService from '../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import Previews from '../../component/Previews';

// label: string;
// deviceId: string;
let prevMicFeedbackStyle = '';
let micFeedBackInteval: any = '';

let localAudio = ZoomVideo.createLocalAudioTrack();
const localVideo = ZoomVideo.createLocalVideoTrack();
let allDevices;

  
const mountDevices: () => Promise<{
  mics: MediaDevice[];
  speakers: MediaDevice[];
  cameras: MediaDevice[];
}> = async () => {
  allDevices = await ZoomVideo.getDevices();

  const cameraDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
    return device.kind === 'videoinput';
  });
  const micDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
    return device.kind === 'audioinput';
  });
  const speakerDevices: Array<MediaDeviceInfo> = allDevices.filter((device) => {
    return device.kind === 'audiooutput';
  });
  return {
    mics: micDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    speakers: speakerDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    cameras: cameraDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    })
  };
};


const updateMicFeedbackStyle = () => {
  const newVolumeIntensity = localAudio.getCurrentVolume();
  let newMicFeedbackStyle = '';

  if (newVolumeIntensity === 0) {
    newMicFeedbackStyle = '';
  } else if (newVolumeIntensity <= 0.05) {
    newMicFeedbackStyle = 'mic-feedback__very-low';
  } else if (newVolumeIntensity <= 0.1) {
    newMicFeedbackStyle = 'mic-feedback__low';
  } else if (newVolumeIntensity <= 0.15) {
    newMicFeedbackStyle = 'mic-feedback__medium';
  } else if (newVolumeIntensity <= 0.2) {
    newMicFeedbackStyle = 'mic-feedback__high';
  } else if (newVolumeIntensity <= 0.25) {
    newMicFeedbackStyle = 'mic-feedback__very-high';
  } else {
    newMicFeedbackStyle = 'mic-feedback__max';
  }
  const micIcon: any = document.getElementById('auido-volume-feedback');
  if (prevMicFeedbackStyle !== '' && micIcon) {
    micIcon.classList.toggle(prevMicFeedbackStyle);
  }

  if (newMicFeedbackStyle !== '' && micIcon) {
    micIcon.classList.toggle(newMicFeedbackStyle);
  }
  console.log(newMicFeedbackStyle, newVolumeIntensity);
  prevMicFeedbackStyle = newMicFeedbackStyle;
};

const { Option } = Select;

const PreviewContainer = () => {
  const [isStartedAudio, setIsStartedAudio] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [activeCamera, setActiveCamera] = useState('');
  const [outputLevel, setOutputLevel] = useState(0);
  const [inputLevel, setInputLevel] = useState(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  const [isPlayingRecording, setIsPlayingRecording] = useState(false);
  const [isInVBMode, setIsInVBMode] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const speakerTesterRef = useRef<TestSpeakerReturn>();
  const microphoneTesterRef = useRef<TestMicrophoneReturn>();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [networkStatus, setNetworkStatus] = useState('Checking...');
  const [micStatus, setMicStatus] = useState('Checking...');
  const [cameraStatus, setCameraStatus] = useState('Checking...');
  const [speakerStatus, setSpeakerStatus] = useState('Checking...');
  const [allDevicesOK, setAllDevicesOK] = useState(false);

const navigate=useHistory()

  useEffect(() => {
    const checkNetworkStatus = () => {
      const updateNetworkStatus = () => {
        setNetworkStatus(
          navigator.onLine ? 'Network OK' : 'Network disconnected',
        );
      };
      updateNetworkStatus();

      window.addEventListener('online', updateNetworkStatus);
      window.addEventListener('offline', updateNetworkStatus);

      return () => {
        window.removeEventListener('online', updateNetworkStatus);
        window.removeEventListener('offline', updateNetworkStatus);
      };
    };
    
    const checkMicrophone = () => {
      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => setMicStatus('Microphone OK'))
          .catch(() => setMicStatus('Microphone not detected'));
      } else {
        setMicStatus('MediaDevices API not supported');
      }
    };
    

    const checkCamera = () => {
      setTimeout(() => {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(() => setCameraStatus('Camera OK'))
          .catch(() => setCameraStatus('Camera not detected'));
      }, 100);
    };

    const checkSpeakers = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        const audioTracks = stream.getAudioTracks();

        if (audioTracks.length > 0) {
          setSpeakerStatus('Speakers OK');
        } else {
          setSpeakerStatus('Speakers not detected');
        }

        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        console.error('Error accessing media devices:', error);
        setSpeakerStatus('Speakers not detected');
      }
    };

    checkNetworkStatus();
    checkMicrophone();
    checkCamera();
    checkSpeakers();
  }, []);

  useEffect(() => {
    if (
      networkStatus === 'Network OK' &&
      micStatus === 'Microphone OK' &&
      cameraStatus === 'Camera OK' &&
      speakerStatus === 'Speakers OK'
    ) {
      setAllDevicesOK(true);
    } else {
      setAllDevicesOK(false);
    }
  }, [networkStatus, micStatus, cameraStatus, speakerStatus]);

  useEffect(() => {
    const getCameraStream = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        if (videoRef.current) {
          // Use type assertion to HTMLVideoElement
          const videoElement = videoRef.current as HTMLVideoElement;
          videoElement.srcObject = mediaStream;
          videoElement.play();
        }

        setStream(mediaStream);
      } catch (err) {
        console.error('Error accessing camera:', err);
        setError(
          'Failed to access camera. Please make sure it is not blocked.',
        );
      }
    };

    getCameraStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
    };
  }, []);

  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await localVideo?.stop();
      setIsStartedVideo(false);
      setIsInVBMode(false);
      setIsBlur(false);
    } else {
      if (videoRef.current) {
        await localVideo?.start(videoRef.current);
        setIsStartedVideo(true);
      }
    }
  }, [isStartedVideo]);
  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await localAudio?.unmute();
        micFeedBackInteval = setInterval(updateMicFeedbackStyle, 500);
        setIsMuted(false);
      } else {
        await localAudio?.mute();
        if (micFeedBackInteval) {
          clearInterval(micFeedBackInteval);
        }
        setIsMuted(true);
      }
    } else {
      await localAudio?.start();
      setIsStartedAudio(true);
    }
  }, [isStartedAudio, isMuted]);

  useEffect(()=>{
    onCameraClick()
    onMicrophoneClick()
  },[])
  const onMicrophoneMenuClick = async (key: string) => {
    const [type, deviceId] = key.split('|');
    if (type === 'microphone') {
      if (deviceId !== activeMicrophone) {
        await localAudio.stop();
        setIsMuted(true);
        localAudio = ZoomVideo.createLocalAudioTrack(deviceId);
        await localAudio.start();
        setActiveMicrophone(deviceId);
      }
    } else if (type === 'leave audio') {
      await localAudio.stop();
      setIsStartedAudio(false);
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (localVideo) {
      if (activeCamera !== key) {
        await localVideo.switchCamera(key);
      }
    }
  };
  const onBlurBackground = useCallback(async () => {
    if (isInVBMode) {
      if (isBlur) {
        await localVideo.updateVirtualBackground(undefined);
      } else {
        await localVideo.updateVirtualBackground('blur');
      }
      setIsBlur(!isBlur);
    } else {
      if (!isBlur) {
        localVideo.stop();
        if (canvasRef.current) {
          localVideo.start(canvasRef.current, { imageUrl: 'blur' });
        }
        setIsInVBMode(true);
        setIsBlur(!isBlur);
      }
    }
  }, [isInVBMode, isBlur]);
  useMount(() => {
    mountDevices().then((devices) => {
      setMicList(devices.mics);
      setCameraList(devices.cameras);
      setSpeakerList(devices.speakers);
      if (devices.speakers.length > 0) {
        setActiveSpeaker(devices.speakers[0].deviceId);
      }
      if (devices.mics.length > 0) {
        setActiveMicrophone(devices.mics[0].deviceId);
      }
    });
  });
  const onTestSpeakerClick = () => {
    if (microphoneTesterRef.current) {
      microphoneTesterRef.current.destroy();
      microphoneTesterRef.current = undefined;
    }
    if (isPlayingAudio) {
      speakerTesterRef.current?.stop();
      setIsPlayingAudio(false);
      setOutputLevel(0);
    } else {
      speakerTesterRef.current = localAudio.testSpeaker({
        speakerId: activeSpeaker,
        onAnalyseFrequency: (value) => {
          setOutputLevel(Math.min(100, value));
        }
      });
      setIsPlayingAudio(true);
    }
  };
  const onTestMicrophoneClick = () => {
    if (speakerTesterRef.current) {
      speakerTesterRef.current.destroy();
      speakerTesterRef.current = undefined;
    }
    if (!isPlayingRecording && !isRecordingVoice) {
      microphoneTesterRef.current = localAudio.testMicrophone({
        microphoneId: activeMicrophone,
        speakerId: activeSpeaker,
        recordAndPlay: true,
        onAnalyseFrequency: (value) => {
          setInputLevel(Math.min(100, value));
        },
        onStartRecording: () => {
          setIsRecordingVoice(true);
        },
        onStartPlayRecording: () => {
          setIsRecordingVoice(false);
          setIsPlayingRecording(true);
        },
        onStopPlayRecording: () => {
          setIsPlayingRecording(false);
        }
      });
    } else if (isRecordingVoice) {
      microphoneTesterRef.current?.stopRecording();
      setIsRecordingVoice(false);
    } else if (isPlayingRecording) {
      microphoneTesterRef.current?.stop();
      setIsPlayingRecording(false);
    }
  };
  let microphoneBtn = 'Test Microphone';
  if (isRecordingVoice) {
    microphoneBtn = 'Recording';
  } else if (isPlayingRecording) {
    microphoneBtn = 'Playing';
  }
  useUnmount(() => {
    if (isStartedAudio) {
      localAudio.stop();
    }
    if (isStartedVideo) {
      localVideo.stop();
    }
  });

  // useEffect(() => {
  //   const storeParamsInSessionStorage = () => {
  //     const urlParams = new URLSearchParams(window.location.search);
  
  //     urlParams.forEach((value, key) => {
  //       sessionStorage.setItem(key, value);
  //     });
  //   };
  
  //   storeParamsInSessionStorage();
  // }, []); 
  const urlParams = new URLSearchParams(window.location.search);
  const role = urlParams.get('user_role')

  const updatePath = (newPath:any) => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);
  
    // Update the path
    currentUrl.pathname = newPath;
  
    // Update the browser's URL without reloading the page
  return currentUrl
  };

  const waitingUpdatePath = (newPath:any) => {
    const currentUrl = new URL(window.location.href);
   currentUrl.pathname = newPath;
    const fullUrl = `${currentUrl.pathname}${currentUrl.search}`;
    window.history.pushState({}, '', fullUrl);
    window.location.reload();
    return currentUrl; 
  };

  const [permissionsGranted, setPermissionsGranted] = useState(false);

  const handleRequestPermissions = async () => {
    try {
      // Request access to both camera and microphone
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      // Permissions granted
      setPermissionsGranted(true);

      // Stop all tracks after permissions are granted
      stream.getTracks().forEach(track => track.stop());
    } catch (err) {
      // Handle errors
      setError('Permission denied or not available');
      console.error('Error accessing media devices:', err);
    }
  };

  const [DocUrl, setURL] = useState('');


//   const Timelog = async () => {
//     try {
//     const urlParams = new URLSearchParams(window.location.search);
//     const topic = urlParams.get('topic') ?? '';
//     const logData = {
//       message:'Media check',
//       sessionId:"2b9de04f-02c9-4f82-906d-9c50b5987ae9",
//       doctor_call_data:{
//         successTime : new Date().toLocaleTimeString()
//       },
//     };
//     // if( waitingUpdatePath('/preview')){
//     await apiService.SendLog(logData)
//     // }
//   }
// catch (error) {
//   console.error('Failed to fetch call ID:', error);
// }
//   };

  // useEffect(()=>{
  //   Timelog()
  // },[])
  useEffect(() => {
    let intervalId: any;
  
    const fetchCallID = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const topic = urlParams.get('topic') ?? '';
        const type = urlParams.get('type') ?? '';
      const DoctorID = urlParams.get('doctorID') || urlParams.get('DoctorID') || '';
        const data = await apiService.GetUrlInput(topic, DoctorID, type);
        setURL(data.data?.doc_url)
      } catch (error) {
        console.error('Failed to fetch call ID:', error);
      }
    };
  
    fetchCallID();
    return () => clearInterval(intervalId);
  }, [history]);

  
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get('type');  
  const meeting_id=queryParams.get("topic")

  const WaitingRoom = async (e: any) => {
    try {
      if (type === 'instantcall') {
       const waiting_room = await apiService.waitingRoomStatus(meeting_id,type);
       waitingUpdatePath('/waitingroom');
       return(waiting_room.data);
      }else{
    waitingUpdatePath('/waitingroom');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const DoctorStatus = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const DoctorID = urlParams.get('doctorID') || urlParams.get('DoctorID') || '';
      const data = await apiService.DocStatusChange(DoctorID);
      window.location.href = DocUrl;
    } catch (error) {
      console.error('Failed to fetch call ID:', error);
    }
  };

  useEffect(() => {
    const scriptId = "hotjar-script";
    if (document.getElementById(scriptId)) return;
    (window as any).hj = (window as any).hj || function () {
      ((window as any).hj.q = (window as any).hj.q || []).push(arguments);
    };
    (window as any)._hjSettings = { hjid: 5235164 };
    const script = document.createElement("script");
    script.id = scriptId;
    script.async = true;
    script.src = `https://static.hj.contentsquare.net/c/csq-${(window as any)._hjSettings.hjid}.js`;
    document.head.appendChild(script);
    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <HeaderProvider>
  <div className="main-body" style={{ backgroundColor:'#f6f6f6' }}>
    <Header/>
   {/* <div className="js-preview-view my-4">
      <div id="js-preview-view" className="container preview__root">
        <span>
          <h3 className='font-weight-bold'>Video Consultation</h3>
        </span>
        <div className="container">
          <div className="row d-flex">
            <div className="col-12 col-md-6 text-center p-md-5" style={{ textAlign: 'center', width: "50%" }}>
            <div className="preview-video">
            <video className={classNames({ 'preview-video-show': !isInVBMode })} muted={true} ref={videoRef} />
            <canvas
              className={classNames({ 'preview-video-show': isInVBMode })}
              width="1280"
              height="720"
              ref={canvasRef}
            />
          </div>
            <div className="video-footer video-operations video-operations-preview">
            <div>
              <MicrophoneButton
                isStartedAudio={isStartedAudio}
                isMuted={isMuted}
                onMicrophoneClick={onMicrophoneClick}
                onMicrophoneMenuClick={onMicrophoneMenuClick}
                microphoneList={micList}
                speakerList={speakerList}
                activeMicrophone={activeMicrophone}
                activeSpeaker={activeSpeaker}
              />
              <CameraButton
                isStartedVideo={isStartedVideo}
                onCameraClick={onCameraClick}
                onSwitchCamera={onSwitchCamera}
                onBlurBackground={onBlurBackground}
                cameraList={cameraList}
                activeCamera={activeCamera}
                isBlur={isBlur}
                isPreview={true}
              />
            </div>
          </div>
            </div>
            <div className="col-12 col-md-6">
            <div className="audio-test">
          <div className="audio-test-wrap">
            <h3>Speaker Test</h3>
            <div className="speaker-action">
              <Button type="primary" onClick={onTestSpeakerClick} className="speaker-btn">
                {isPlayingAudio ? 'Stop' : 'Test Speaker'}
              </Button>
              <Select
                onChange={(value) => {
                  setActiveSpeaker(value);
                }}
                value={activeSpeaker}
                className="speaker-list"
              >
                {speakerList.map((item) => {
                  return (
                    <Option value={item.deviceId} key={item.deviceId}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="speaker-output">
              <span className="speaker-label">Output level</span>
              <Progress percent={outputLevel} showInfo={false} />
            </div>
          </div>
          <div className="audio-test-wrap">
            <h3>Microphone Test</h3>
            <div className="speaker-action">
              <Button type="primary" onClick={onTestMicrophoneClick} className="speaker-btn ">
                {microphoneBtn}
              </Button>
              <Select
                onChange={(value) => {
                  setActiveMicrophone(value);
                }}
                value={activeMicrophone}
                className="speaker-list"
              >
                {micList.map((item) => {
                  return (
                    <Option value={item.deviceId} key={item.deviceId}>
                      {item.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="speaker-output">
              <span className="speaker-label">Input level</span>
              <Progress percent={inputLevel} showInfo={false} />
            </div>
          </div>
        </div>
            </div>
          </div>
       
        <div className='gap-5 mt-5'> 
          <h5>
          Supported web browsers for this video consultation technology include the latest versions of google chrome, Mozilla firefox</h5>
         {role==='doctor'?<a href={`${updatePath('/doctor')}`} className='text-white'><button className='btn btn-primary mt-2 join-button'>Continue to Join call</button></a>:<a  href={`${updatePath('/waitingroom')}`} className='text-white'><button className='btn btn-primary mt-2 join-button'>Continue to Waitingroom</button></a>}
          
        </div>
        </div>
     
      </div>
    </div>  */}
 <div className="container my-5 mx-auto flex-container">
  <h3 className="mb-4">Video Consultation</h3>

  <div className="row mt-4 pt-md-4">
    <div className="col-md-6 d-flex justify-content-center align-items-center">
      <div style={{
        width: '100%',
        maxWidth: '480px',
        height: '100%',
        backgroundColor: '#343a40',
        color: 'white',
        textAlign: 'center'
      }}>
        <div className="preview-video">
          <video className={classNames({ 'preview-video-show': !isInVBMode })} muted={true} ref={videoRef} />
          <canvas className={classNames({ 'preview-video-show': isInVBMode })} ref={canvasRef} />
        </div>
      </div>
    </div>
    <div className="col-md-4 align-self-center">
      <div className="d-grid gap-3">
        <div className="p-2 my-1 bg-light border d-flex justify-content-between align-items-center rounded shadow-sm" style={{ backgroundColor: 'white' }}>
          <div><img src="/icon/network.svg" alt="" /><span className='ps-3'>Network</span></div>  {networkStatus === 'Checking...' ||
              networkStatus === 'Network disconnected' ? (<img src="/icon/cancel.svg" alt="" width={24}/>):(<img src="/icon/correct.svg" alt="" width={24}/>)}
        </div>
        <div className="p-2 my-1 bg-light border d-flex justify-content-between align-items-center rounded shadow-sm" style={{ backgroundColor: 'white' }}>
          <div><img src="/icon/microphone.svg" alt="" /><span className='ps-3'>Microphone</span></div>  {micStatus === 'Checking...' ||
              micStatus === 'Microphone not detected' ? (<img src="/icon/cancel.svg" alt="" width={24}/>):(<img src="/icon/correct.svg" alt="" />)}
        </div>
        <div className="p-2 my-1 bg-light border d-flex justify-content-between align-items-center rounded shadow-sm" style={{ backgroundColor: 'white' }}>
          <div><img src="/icon/camera.svg" alt="" /><span className='ps-3'>Camera</span></div>  {cameraStatus === 'Checking...' ||
              cameraStatus === 'Camera not detected' ? (<img src="/icon/cancel.svg" alt="" width={24}/>):(<img src="/icon/correct.svg" alt="" />)}
        </div>
        <div className="p-2 my-1 bg-light border d-flex justify-content-between align-items-center rounded shadow-sm" style={{ backgroundColor: 'white' }}>
          <div><img src="/icon/speaker.svg" alt="" /><span className='ps-3'>Speaker</span></div>  {speakerStatus === 'Checking...' ||
              speakerStatus === 'Speakers not detected' ? (<img src="/icon/cancel.svg" alt="" width={24}/>):(<img src="/icon/correct.svg" alt="" />)}
        </div>
      </div>
     
    </div>
  </div>
  <div className="row mt-4 pt-md-4 text-center">
    <div className="col">
      <div><span className='fs-5 fw-normal'>Supported web browsers for this video consultation technology</span></div>
      <div><span className='fs-5 fw-normal'>include the latest versions of Google Chrome, Mozilla Firefox and Apple Safari.</span></div>
      <Previews/>
      {allDevicesOK ? (
  role === 'doctor' ? (
    DocUrl && (
      <button onClick={()=>DoctorStatus()} className="btn btn-primary mt-2 join-button">Continue to Join Call</button>
    )
  ) : (
    // <a href={`${updatePath('/waitingroom')}`} className="text-white my-5">
      <button className="btn btn-primary mt-2 join-button" onClick={WaitingRoom}>Continue to Waiting Room</button>
  //  </a>
  )
) : (
  <p style={{ color: 'red' }} onClick={handleRequestPermissions}>
    Please ensure all media devices are functioning properly.
  </p>
)}


    </div>
  </div>
</div>


       </div>

    </HeaderProvider>
  
 
  );
};

export default PreviewContainer;
