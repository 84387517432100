/* eslint-disable prettier/prettier */
import React, { useState, useContext, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import classnames from 'classnames';
// import _ from 'lodash';
import { RouteComponentProps, useHistory, Link } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useAvatarAction } from './hooks/useAvatarAction';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import ReportBtn from './components/report-btn';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './video.scss';
import apiService from '../../services/api';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import useFcmToken from '../../hooks/useFCMToken';
import Modal from '../../component/Model';
import LoadingLayer from '../../component/loading-layer';
import PipActivateModel from '../../component/pipActivateModel';
import PipClosedModel from '../../component/pipClosedModel';
// import classNames from 'classnames';
// import myService from '../../services/service'
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';
// import blinkSound from 'audio/blink-93025.mp3';

interface User {
  display_name: string;
  name: string;
  gender: string;
  dob: string;
  ssno: string;
  profile_picture: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  phone: string;
  dea_no: string;
  npi_no: string;
  signature: string;
}

interface PatientUser {
  height: string;
  weight: string;
  bmi: string;
}
interface Prescription {
  drug_name: string;
  dosage: string;
  frequency: string;
  notes: string;
  days: string;
}

interface Notes {
  id: any;
  practitioner_notes: string;
  documentation_notes: string;
  referal_notes: string;
}

interface userID {
  from_user_id: string;
}
interface Specialty {
  name: string;
}
interface Question {
  id: string;
  name: string;
  answer?: string; // Make answer optional if it may not exist initially
  questions: Question[];
}

interface SelectedIdFormatted {
  lab_test_id: string;
}

const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const { token, notificationPermissionStatus } = useFcmToken();
  const [fcmToken, setFcmToken] = useState('');
  useEffect(() => {
    if (token) {
      setFcmToken(token);
      console.log('FCM Token:', token);
    }
  }, [token]);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  const avatarActionState = useAvatarAction(zmClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zmClient);
  console.log(visibleParticipants);
  window.sessionStorage.setItem('visibleParticipants', visibleParticipants.length.toString());
  
  const [PdfURL, setPdfURL] = useState('');
  interface Drug {
    id: string;
    reg_no: string;
  }

  const [HideAll, setHideAll] = useState(true);

  const [HideWaiting, setHideWaiting] = useState(false);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);

  useEffect(() => {
    if (!HideAll) {
      return;
    }

    const intervalId = setInterval(() => {
      hideElement();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [HideAll]);

  const hideElement = async () => {
    console.log('hide element');
    
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type') ?? ''; // Use '' as default value if null
    const userRole = urlParams.get('user_role') ?? ''; // Use '' as default value if null

    if (type === 'waitingroom') {
      setHideWaiting(true);
      setHideAll(false);
    console.log('waitingroom');

    } else {
      setHideWaiting(false);
      console.log('appointment');
    

      const callID = await apiService.GetCallID();
      const UserDetailsID = await apiService.viewUser();
      // setChats(UserDetailsID.data.chatHistory);
      // const User_Details = await apiService.viewUser();
      // ProjectUrl(User_Details.data.hospital_info.zoom_video_url)
    


      if (callID.data.call_detail_id) {
        if (apiCallSuccess) return;
      console.log('received call id');

        try {
          const User_Details = await apiService.viewId(
            callID.data.call_detail_id,
            UserDetailsID.data.callData.to_user_id
          );
          // Handle the success condition
          setHideAll(false);

          console.log(HideAll);
          setApiCallSuccess(true);
          // You can also add other logic here that you want to execute on success
          
        } catch (error) {
          // Handle the error condition
          // setHideAll(true);
          console.error('Error fetching user details:', error);
          // You can also add other logic here that you want to execute on error
        }
      } else {
      console.log('no call id');

        const urlParams = new URLSearchParams(window.location.search);
        const topic = urlParams.get('topic') ?? ''; // Use '' as default value if null
        const callID = urlParams.get('callID') ?? ''; // Use '' as default value if null

        if (callID !== '') {
          if (apiCallSuccess) return;
          try {
            const User_Details = await apiService.viewId(callID, UserDetailsID.data.to_user_id);

            setHideAll(false);
            setApiCallSuccess(true);
            console.log('User Details:', User_Details);
          } catch (error) {
            // setHideAll(true);
            console.error('Error fetching user details:', error);
          }
        }
      }
    }
  };


  const [Orders, setOrders] = useState([]);
  const [user, userDetails] = useState<User | null>(null);
  const [ UserAllDetails, SetuserAllDetails] = useState<any>('');
  const [UserData, SetUserData] = useState<any>(null);
  const [userPat, userDetailsPat] = useState<User | null>(null);
  const [PatientUser, userDetailsPatientUser] = useState<PatientUser | null>(null);
  const [patient, PatientDetails] = useState<User | null>(null);
  const [prescription, setPrescription] = useState([]);
  const [clinical, setClinical] = useState([]);
  const [drugName, setDrugName] = useState('');
  const [drugID, setDrugID] = useState('');
  const [dosage, setDosage] = useState('');
  const [PatientImg, setPatientImg] = useState('');
  const [DoctorImg, setDoctorImg] = useState('');
  const [frequency, setFrequency] = useState('');
  const [note, setNote] = useState('');
  const [days, setDays] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [questions, setQuestions] = useState<any>([]);
  const [history, setVideoHistory] = useState([]);
  const [questions1, setQuestions1] = useState('');
  const [themeConfig, setThemeConfig] = useState('');
  const [callID, setCallID] = useState<string | null>(null);
  const [meetingID, setMeetingID] = useState('');
  const [chats, setChats] = useState<any>([]);
  const [ChatTrigger, setChatTrigger] = useState(false);
  const [notes, setNotes] = useState<Notes | null>(null);
  const [userID, setUserID] = useState<userID | null>(null);
  const [drugs, allDrugs] = useState<Drug[]>([]);
  const [WaitingList, setWaitingList] = useState<any>([]);
  const [WaitingListCount, setWaitingListCount] = useState([]);
  const [AvailableDoctorsCount, setAvailableDoctors] = useState([]);
  const [messageCount, setAvailableMessages] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [specialty, setSpecialty] = useState<Specialty[]>([]);
  const [publish, setIs_published] = useState('');

  const [editableAnswers, setEditableAnswers] = useState<any>([]);
  const [editablePresNotes, setEditablePresNotes] = useState('');
  const [editableDocNotes, setEditableDocNotes] = useState<{ [key: string]: string }>({});
  const [editableRefNotes, setEditableRefNotes] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const buttonRefWait = useRef<HTMLButtonElement>(null);
  const [physician, physicianValue] = useState('');
  const [appointmentDetails, setAppointmentDetails] = useState('');

  

  useEffect(() => {
    const urlParameter = new URLSearchParams(window.location.search);
    const baseUrl = urlParameter.get('Production') ?? '';
    console.log(baseUrl, 'baseUrl');
    const newPath = baseUrl.replace('/web/api/', '');
    console.log(newPath, 'baseUrl');

    setPdfURL(newPath);
  }, []);

  const [textInput, setTextInput] = useState('');
  const handleInputChange = (event: any) => {
    setTextInput(event.target.value);
  };

  const [attachment, setAttachment] = useState(null);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return; // If no file is selected, return early
  
    setAttachment(file);
    const tempId = Date.now(); // Temporary ID for this file message
    const tempChat = {
      id: tempId,
      sender_id: userId,
      message: '',
      attachement: file.name.endsWith('.pdf') ? 'images/pdf.png' : URL.createObjectURL(file),
      created_at: new Date(),
      sender_name: 'Me',
    };
  
    // Show "Sending..." file temporarily
    setChats((prevChats: any) => [...prevChats, tempChat]);
  
    try {
      const postsData = await apiService.sendAttachmentDoc(file, UserAllDetails);
  

      if (Array.isArray(postsData?.data)) {
        setChats((prevChats: any) =>
          prevChats.map((chat: any) =>
            chat.id === tempId ? { ...postsData.data[postsData.data.length - 1] } : chat
          )
        );
  
        setAvailableMessages(postsData.unread_message_count);
        setChatTrigger(true);
      } else {
        console.error('Unexpected response structure:', postsData);
        // Optionally remove the temporary message or show an error message to the use
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "File Attachment failed",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: 'small-swal'
          }
        });
        
        setChats((prevChats: any) =>
          prevChats.filter((chat: any) => chat.id !== tempId) // Remove the temporary message if needed
        );
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error by possibly removing the temporary message
      setChats((prevChats: any) =>
        prevChats.filter((chat: any) => chat.id !== tempId) // Remove the temporary message on error
      );
    }
    event.target.value = null; // Reset the file input after handling the file
  };
  
  
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const tempId = Date.now(); // Temporary ID for this message
  
    const send = async (textInput: string) => {
      if (textInput.trim() === '') return;
  
      const tempChat = {
        id: tempId,
        sender_id: userId,
        message: 'Sending...', // Temporary message text
        attachement: '',
        created_at: new Date(),
        sender_name: 'Me',
      };
  
      // Show "Sending..." message temporarily
      setChats((prevChats: any) => [...prevChats, tempChat]);
  
      try {
        const postsData = await apiService.sendMessage(textInput, UserAllDetails);
        
        if (postsData?.data?.length > 0) {
          setChats((prevChats: any) =>
            prevChats.map((chat: any) =>
              chat.id === tempId ? { ...postsData.data[postsData.data.length - 1] } : chat
            )
          );
  
          setAvailableMessages(postsData.unread_message_count);
        } else {
          // Handle case where data is not as expected
          console.error('Unexpected response structure:', postsData);
          // Optionally remove the temporary chat or show an error message
          setChats((prevChats: any) =>
            prevChats.filter((chat: any) => chat.id !== tempId) // Remove the temporary message
          );
        }
        
        setChatTrigger(true);
      } catch (error) {
        console.error('Error sending message:', error);
        // Optionally remove the temporary chat on error
        setChats((prevChats: any) =>
          prevChats.filter((chat: any) => chat.id !== tempId) // Remove the temporary message
        );
      }
    };
  
    send(textInput);
    setTextInput('');
  };
  
  
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('End Session...');

  const ZoomRecordingLeave = async () => {
    try {
      await apiService.ZoomRecording();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        saveNot();
    } catch (error) {
      console.error('Error in ZoomRecordingLeave:', error);
    }
  };

  const waitingUpdatePath = (newPath:any) => {
    const currentPath = window.location.pathname;
    return currentPath === newPath;
  };
  const [statusloag,setStatuslog]=useState(false)
  const checkPath = (path: string) => window.location.pathname === path;

  const Videolog = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const meetingID = urlParams.get('meetingID') ?? '';
    const topic = urlParams.get('topic') ?? '';
    const logData = {
      timestamp: new Date(),
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      sessionId:waitingUpdatePath('/doctor') ?meetingID:topic,
      doctor_call_data:{
        successTime : new Date().toLocaleTimeString(),
        ...checkPath('/doctor') && !statusloag ?{  
           message: 'Doctor Leave the Room',
            status:3
        }:{}
      },
    };
    await apiService.SendLog(logData)
  };
  const handleLeaveRoomClick = (event: any) => {
    event.preventDefault();
    const userID = window.sessionStorage.getItem('userID');
    LeaveRoom(userID);
    console.log(statusloag,'statusloag');
    LeaveRoomStatus();
    ZoomRecordingLeave();
    Videolog()
  };

  const LeaveRoom = async (userID: any) => {
    setLoadingText('Leaving the room...')
    setIsLoading(true);
    try {
      const leave_room = await apiService.LeaveRoomDoc(userID);
      if (leave_room !== '') {
        const urlParameter = new URLSearchParams(window.location.search);
        const baseURL = urlParameter.get('baseUrl') || '';
        const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
        
        const url = new URL(baseURL);
        
        url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
        
        if (doctorID) {
          url.searchParams.append('id', doctorID);
        }
        
   
        window.location.href = url.toString();
      }
    } catch (error) {
      const urlParameter = new URLSearchParams(window.location.search);
      const baseURL = urlParameter.get('baseUrl') || '';
      const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
      
      const url = new URL(baseURL);
      
      url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
      
      if (doctorID) {
        url.searchParams.append('id', doctorID);
      }
      
 
      window.location.href = url.toString();
     }
  };



  const LeaveRoomStatus = async () => {
    setLoadingText('Leaving the room...')
    setIsLoading(true);
    const urlParameter = new URLSearchParams(window.location.search);
    const doctorID = urlParameter.get('doctorID') ?? '';
    const callID = urlParams.get('callID') ?? '';
    try {
      const leave_room = await apiService.LeaveRoomStatusChange(doctorID, callID);
      if (leave_room !== '') {
        const urlParameter = new URLSearchParams(window.location.search);
        const baseURL = urlParameter.get('baseUrl') || '';
        const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
        
        const url = new URL(baseURL);
        
        url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
        
        if (doctorID) {
          url.searchParams.append('id', doctorID);
        }

        window.location.href = url.toString();
        
      }
    } catch (error) { 
      const urlParameter = new URLSearchParams(window.location.search);
      const baseURL = urlParameter.get('baseUrl') || '';
      const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
      
      const url = new URL(baseURL);
      
      url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
      
      if (doctorID) {
        url.searchParams.append('id', doctorID);
      }

      window.location.href = url.toString();
    }
  };

 

  const handleSelectChang = (event: any) => {
    const selectedId = event.target.value;
    const selectedItem = drugs.find((item: any) => item.id === selectedId);
    if (selectedItem) {
      setDrugName(selectedItem.reg_no); // Set drugName to the name of the selected item
      setDrugID(selectedItem.id); // Set drugName to the name of the selected item
    }
  };



  useEffect(() => {
    const urlParameter = new URLSearchParams(window.location.search);
    const appid = urlParameter.get('APPID') ?? '';
    window.sessionStorage.setItem('AppID', appid);
    console.log(appid);
    const doctorID = urlParameter.get('doctorID') ?? '';
    window.sessionStorage.setItem('userID', doctorID);
  }, []);
  const userId = window.sessionStorage.getItem('userID');

  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);

  const handleAddDrug = async () => {
    // Check if any of the input fields are empty
    if (!drugName || !dosage || !frequency || !note || !days) {
      console.error('Cannot add prescription: Input fields are empty');
      return;
    }

    const newPrescription = {
      sys_drug_id: drugID,
      drug_name: drugName,
      dosage: dosage,
      frequency: frequency,
      notes: note,
      days: days
    };

    setPrescriptions([...prescriptions, newPrescription]);
    setDrugName('');
    setDrugID('');
    setDosage('');
    setFrequency('');
    setNote('');
    setDays('');
    console.log([...prescriptions, newPrescription], 'new');
  };

  // Function to handle checkbox click
  const handleCheckboxClick = (id: string) => {
    // Check if the ID is already selected
    if (selectedIds.includes(id)) {
      // If selected, remove it from the array
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      // If not selected, add it to the array
      setSelectedIds([...selectedIds, id]);
    }
  };

  const [selectedIdsFormatted, setSelectedIdsFormatted] = useState<SelectedIdFormatted[]>([]);
  const [Config, setConfig] = useState(false);

  // const canvas = videoRef.current;
  // // Set width and height
  // if (canvas) {
  //   // Set width and height
  //   canvas.width = 630;
  //   canvas.height = 450;
  // }

  useEffect(() => {
 
    const selectedIdsFormatted = selectedIds.map((selectedId) => ({ lab_test_id: selectedId }));
    setSelectedIdsFormatted(selectedIdsFormatted);

    const fetchOrders = async () => {
      try {
        const OrdersData = await apiService.clinicalOrder();
        setClinical(OrdersData.data.OrderMaster);
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };

    const videoHistory = async () => {
      try {
        const video_HISTORY = await apiService.videoHistory();
        setVideoHistory(video_HISTORY.data.history);
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };

    const AvailableDoctors = async () => {
      try {
        const GetAvailDoctors = await apiService.GetDoctors();
        console.log(GetAvailDoctors.data, 'GetAvailDoctors');
        setAvailableDoctors(GetAvailDoctors.data);
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };


    // Get the current URL
    // const url =
    //   'https://oklmabxw4wyt7rgylppc2rfla4.apigateway.me-dubai-1.oci.customer-oci.com/doctor?topic=&sessionKey=MjQwMjI4MTUzMDI3&user_role=doctor&callID=&meetingID=d075ae3b-7b8b-46d9-9d62-eefbbcc653df&meetID=240226123653&baseUrl=https://testhospital.instapract.ae/web/front';

    // // Create a URLSearchParams object with the URL query parameters
    // const urlParams = new URLSearchParams(url.split('?')[1]);

    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get('topic') ?? ''; // Use '' as default value if null
    const sessionKey = urlParams.get('sessionKey') ?? ''; // Use '' as default value if null
    const userRole = urlParams.get('user_role') ?? ''; // Use '' as default value if null
    const callID = urlParams.get('callID') ?? ''; // Use '' as default value if null
    const meetingID = urlParams.get('meetingID') ?? ''; // Use '' as default value if null
    const meetID = urlParams.get('meetID') ?? ''; // Use '' as default value if null
    const baseUrl = urlParams.get('baseUrl') ?? ''; // Use '' as default value if null

    fetchOrders();
    viewUser();
    // setInterval(viewUser, 50000);
    videoHistory();
    hospitalLogo();
    AvailableDoctors();
    setInterval(AvailableDoctors, 30000);
    setReason(false);
  }, [selectedIds, apiCallSuccess]);

  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const [url, setUrl] = useState("");


  const viewUser = async () => {
    try {
      const userDetails = await apiService.viewUser();
      // Set data based on User_Details 
      AllchatHis(userDetails)
      hospitalConfigurationAll(userDetails)
      themeConfiguration(userDetails)
      SetuserAllDetails(userDetails);
      console.log(userDetails.data.fromUser,'userDetails.data.fromUser');
      userDetailsPat(userDetails.data.fromUser);
      userDetailsPatientUser(userDetails.data.patientUser);
      setPatientImg(userDetails.data.fromUser.profile_picture);
      setAvailableMessages(userDetails.data.chatHistory.length);
      setDoctorImg(userDetails.data.toUser.profile_picture);
      window.sessionStorage.setItem('userID', userDetails.data.callData.to_user_id);
      PatientDetails(userDetails.data.fromUser);
      setQuestions(userDetails.data.callData.pre_call.questionType);
      runningNose(questions1);
      setSpecialty(userDetails.data.toUser);
      setNotes(userDetails.data.callData);
      setUserID(userDetails.data.callData.to_user_id);
      setPrescription(userDetails.data.prescriptions);
      updateUrlParams(userDetails?.data?.copylink?.doc_name, userDetails?.data?.copylink?.doc_id)
      
      setInterval(() => AllchatHis(userDetails), 20000); 
      // Update document title and call myService.updateData
      document.title = userDetails.data.hospital_info.abbrevation;
      // myService.updateData(userDetails);
          console.log('Title', userDetails.data.hospital_info.abbrevation);

    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  
  const updateUrlParams = (newName: any, newDoctorID: any) => {
    if (!newName || !newDoctorID) {
      console.log('Invalid inputs, returning empty');
      return '';
    }
    const urlObject = new URL(currentUrl);
    const params = new URLSearchParams(urlObject.search);

    // Set the new values for the parameters
    params.set("name", newName);
    params.set("doctorID", newDoctorID);

    // Update the URL with the modified search parameters
    urlObject.search = params.toString();
    setUrl(urlObject.toString());
    console.log(urlObject.toString(),'urlObject.toString()');
    
  };

  const [isCopied, setIsCopied] = useState(false);

  // Replace this with the actual URL you want to copy
  const linkToCopy = url;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset message after 2 seconds
        Swal.fire({
          position: 'top',
          title: 'Link successfully copied!', // Your message
          showConfirmButton: false, // No confirmation button
          timer: 1000, // Auto-close after 1 second (1000 milliseconds)
        });
        
      })
      .catch((err) =>{ console.error("Failed to copy text:", err)
        Swal.fire({
          position: 'top',
          title: 'Failed to copy link!', // Your message
          showConfirmButton: false, // No confirmation button
          timer: 1000, // Auto-close after 1 second (1000 milliseconds)
          customClass: {
            popup: 'custom-swal-popup',
            title: 'custom-swal-title',
          },
        });
        
      });
  };
  const AllchatHis = async (User_Details: any) => {
    try {
      const chatHis = await apiService.chatHistory(User_Details);
      console.log('chatHis', chatHis);
      if (chatHis?.data) {
        setChats(chatHis.data);
        setAvailableMessages(chatHis.unread_message_count);
      } else {
        console.error("Invalid chat history response:", chatHis);
      }      
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };
  const audioRef = useRef(new Audio('/audio/mixkit-bell-notification-9333.mp3')); 

  const waitingList = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userID = urlParams.get('doctorID');
      const waitingList = await apiService.waitingList(userID, fcmToken);
      console.log(waitingList.data, 'waitingList');
      setWaitingList(waitingList.data);
      setWaitingListCount(waitingList.wait_count);
  
      if (waitingList?.tone) {
        // Convert "00:15" timer string to seconds
        const [minutes, seconds] = waitingList.timer.split(':').map(Number);
        const totalSeconds = minutes * 60 + seconds;
  
        // Play the audio
        audioRef.current.play();
  
        // Stop the audio after the specified duration
        setTimeout(() => {
          audioRef.current.pause();
          audioRef.current.currentTime = 0; // Reset audio to the beginning
        }, totalSeconds * 1000);
      }
  
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };
  
  
  // Helper function to convert timer string "00.15" to seconds
  const convertTimerToSeconds = (timer: any) => {
    const [minutes, seconds] = timer.split('.').map(Number);
    return (minutes * 60) + seconds;
  };
  


  const hospitalConfigurationAll = async (userDetails: any) => {
    try {
      const configure = await apiService.hospitalConfiguration(userDetails);
      if (configure.data.questionnaire === 'off') {
        setQuestionnaire(false);
      }
      if (configure.data.patprofile === 'off') {
        setPatProfile(false);
      }
      if (configure.data.chat === 'off') {
        setChatsHide(false);
      }
      if (configure.data.notes === 'off') {
        setNotesHide(false);
      }
      if (configure.data.prescription === 'off') {
        setPrescriptionHide(false);
      }
      if (configure.data.icdcode === 'off') {
        setIcdCodeHide(false);
      }
      if (configure.data.absenceform === 'off') {
        setAbsenceFormHide(false);
      }
      if (configure.data.specialityform === 'off') {
        setSpecialtyFormHide(false);
      }
      if (configure.data.lab_orders === 'off') {
        setLabOrdersHide(false);
      }
      if (configure.data.waiting_list === 'off') {
        setWaitingRoomHide(false);
      }
      if (configure.data.call_forward === 'off') {
        setCallForwardHide(false);
      }

    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };
  const [alert, setAlert] = useState(false); 
  const [alertContent, setAlertContent] = useState(''); 
  const themeConfiguration = async (userDetails: any) => {
    try {
      const theme_Config = await apiService.themeConfiguration(userDetails);
      setThemeConfig(theme_Config.data.video_font_icon);
      if (theme_Config.data.alert === 'on') {
        setAlert(true)
        setAlertContent(theme_Config.data.alert_content)
        // Swal.fire({
        //   position: 'top',
        //   title: theme_Config.data.alert_content,
        //   showConfirmButton: false,
        //   showCloseButton: true, // Enable the close button
        //   customClass: {
        //     popup: 'custom-swal-popup',
        //     title: 'custom-swal-title',
        //     closeButton: 'custom-swal-close', // Custom class for the close button (optional)
        //   },
        // });
      }
      const root = document.documentElement;
      root.style.setProperty('--theme-bg', theme_Config.data.video_font_icon);
      document.title = theme_Config?.hospita_name
      window.sessionStorage.setItem('hospital_name', theme_Config?.hospita_name)
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const [hasPlayedSound, setHasPlayedSound] = useState(false);

  // const [play] = useSound('audio/blink-93025.mp3');

  // useEffect(() => {
  //   if (WaitingList.length !== 0 && !hasPlayedSound) {
  //     play();
  //     setHasPlayedSound(true);
  //   }
  // }, [WaitingList, hasPlayedSound, play]);

  useEffect(() => {
    if (WaitingList.length !== 0 && !hasPlayedSound) {
      playBlinkSound();
      setHasPlayedSound(true);
    }
  }, [WaitingList, hasPlayedSound]);

  const playBlinkSound = () => {
    const audio = new Audio('audio/blink-93025.mp3');
    audio.play().catch((error: any) => {
      // Handle error gracefully, such as logging it
      console.error('Failed to play audio:', error);
    });
  };
  const DownloadDoc = async (id: any) => {
    try {
      const Document = await apiService.ViewDocument(id);
      const pdfData = Document;
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const formatDate = (dateString: any) => {
    // Assuming dateString is in yyyy-mm-dd format
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}-${month}-${year}`;
  };

  const [WaitActiveTab, setWaitActiveTab] = useState('');
  const [AppActiveTab, setAppActiveTab] = useState('');
  const [ForwardActiveTab, setForwardActiveTab] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type') ?? '';
    const callID = urlParams.get('callID') ?? '';

    if (type === 'waitingroom' && callID === '') {
      setWaitActiveTab('active');
      setAppActiveTab('');
    } else {
      setWaitActiveTab('');
      setAppActiveTab('active');
    }

    waitingList();
    if (callID !== '') {
      setInterval(updateCallTime, 30000);
    }

    const intervalId = setInterval(waitingList, 30000);
    return () => clearInterval(intervalId);
  }, [fcmToken]);

  const questionAnswers = async () => {
    try {
      const questionAnswer = await apiService.questionAnswer(UserAllDetails, editableAnswers, questions);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const saveNot = useCallback(async () => {
    const userDetails = await apiService.viewUser();
    try {
 await apiService.saveNotes(
        prescriptions,
        absenceData,
        codes,
        userDetails,
        selectedIdsFormatted,
        physician,
        physicianNotes,
        editablePresNotes,
        editableRefNotes,
        editableAnswers,
      );
      const urlParams = new URLSearchParams(window.location.search);
      const meetingID = urlParams.get('meetingID') ?? ''; // Use '' as default value if null
      const baseUrl = urlParams.get('baseUrl') ?? ''; // Use '' as default value if null
      endCallTime(userDetails.data.callData.id);
      await apiService.ZoomRecording();
      // const MeetingID = window.sessionStorage.getItem('meetingID');
      // historyS.push(`https://uae-saas.instapract.ae/web/front/video/get-survey-questions?meeting_id=${MeetingID}`);
      // window.location.href = `${baseUrl}/video/get-survey-questions?meeting_id=${meetingID}`;
      window.sessionStorage.setItem('popup', '');
      const urlParameter = new URLSearchParams(window.location.search);
          const baseURL = urlParameter.get('baseUrl') || '';
          const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
          
          const url = new URL(baseURL);
          
          url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
          
          if (doctorID) {
            url.searchParams.append('id', doctorID);
          }
          
          window.location.href = url.toString();
    } catch (error) {
      console.error('Error fetching Orders:', error);
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Something went wrong!',
      //   text: 'Something went wrong!'
      //   // footer: '<a href="#">Why do I have this issue?</a>'
      // });
      saveNot();
    }
  }, [editablePresNotes, editableRefNotes]);

  interface Item {
    id: string;
    icd_code: string;
  }

  const [value, setSelectValue] = useState([]);
  const [value2, setSelectValue2] = useState([]);
  const [value3, setSelectValue3] = useState([]);
  const [codes, setCodes] = useState<Item[]>([]);

  const handleIcd = (event: any) => {
    if (event) {
      setCodes((prevCodes) => [...prevCodes, event]);
    }
  };

  useEffect(() => {
    // setHideForm(!FormHide);
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type') ?? ''; // Use '' as default value if null
    window.sessionStorage.setItem('type', type);

    if (type === 'waitingroom') {
      const waitactive = 'active show';
    } else {
      const waitactive = '';
    }

    if (type === 'appointment') {
      const active = 'active show';
    } else {
      const active = '';
    }
  }, [codes]);

  const handleIcd2 = (event: any) => {
    const selectedId = event.target.value;
    const selectedItem = value2.find((item: any) => item.id === selectedId);
    if (selectedItem) {
      setCodes((prevCodes) => [...prevCodes, selectedItem]);
    }
  };

  const handleIcd3 = (event: any) => {
    const selectedId = event.target.value;
    const selectedItem = value3.find((item: any) => item.id === selectedId);
    if (selectedItem) {
      setCodes((prevCodes) => [...prevCodes, selectedItem]);
    }
  };

  const handleChange = async (e: any) => {
    try {
      const SelectData = await apiService.icdList(e);
      setSelectValue(SelectData.data);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const handleChange2 = async (e: any) => {
    try {
      const SelectData = await apiService.icdList(e);
      setSelectValue2(SelectData.data);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const handleChange3 = async (e: any) => {
    try {
      const SelectData = await apiService.icdList(e);
      setSelectValue3(SelectData.data);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const drugList = async (e: any) => {
    const inputValue = e.target.value;
    try {
      const Drugs = await apiService.drugList(inputValue);
      allDrugs(Drugs.data);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };
  const [activeTab, setActiveTab] = useState('');
  const [forwardValue, setForwardValue] = useState('');

  const setForward = (data: any) => {
    console.log('setForwardData', data);
    setForwardActiveTab('active');
    setForwardValue(data);
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const CallForward = async (data: any) => {
    console.log('forwardValue', forwardValue);
    try {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--theme-bg)',
        cancelButtonColor: '#A0A2B3',
        confirmButtonText: 'Yes, Forward it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const User_Details = await apiService.viewUser();
            if (UserAllDetails) {
              const JoinCall = await apiService.CallForwardUser(data, User_Details);
              Swal.fire({
                title: 'Forwarded!',
                icon: 'success'
              });

              const urlParams = new URLSearchParams(window.location.search);
              const topic = urlParams.get('doctorID') ?? ''; // Use '' as default value if null
              const sessionKey = urlParams.get('sessionKey') ?? ''; // Use '' as default value if null
              const userRole = urlParams.get('user_role') ?? ''; // Use '' as default value if null
              const baseUrl = urlParams.get('baseUrl') ?? ''; // Use '' as default value if null
              const type = 'appointment';
              // const type = urlParams.get('type') ?? '';
              const doctorID = urlParams.get('doctorID') ?? ''; // Use '' as default value if null
              const name = urlParams.get('name') ?? ''; // Use '' as default value if null
              const APPID = urlParams.get('APPID') ?? ''; // Use '' as default value if null
              const Production = urlParams.get('Production') ?? ''; // Use '' as default value if null
              window.location.href = `${ProjectURL}/doctor?topic=${topic}&sessionKey=${sessionKey}&user_role=${userRole}&callID=&meetingID=&meetID=&baseUrl=${baseUrl}&type=${type}&doctorID=${doctorID}&name=${name}&APPID=${APPID}&Production=${Production}`;
            }
          } catch (error) {
            // Handle error if needed
          }

          const urlParams = new URLSearchParams(window.location.search);
          const meeting_ID = urlParams.get('meetingID') ?? '';

          if (forwardValue !== '') {
            const JoinCall = await apiService.CallForward(data, forwardValue);
            Swal.fire({
              title: 'Forwarded!',
              icon: 'success'
            });
            setForwardValue('');
          } else {
            if (meeting_ID === '') {

              // alert('please choose patient');
              if (buttonRefWait.current) {
                buttonRefWait.current.click();
              }
            }
          }
        }
      });
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const savePublishAlert = (data: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get('topic') ?? '';
    const callID = urlParams.get('callID') ?? '';
    if (callID !== '') {
      Swal.fire({
        title: 'You will be ending the current session. Do you want to save your notes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        denyButtonText: `Publish`,
        confirmButtonColor: 'var(--theme-bg)', // Set the color for the confirm button
        denyButtonColor: '#28245F'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // Swal.fire('Saved!', '', 'success');
          JoinCall(data);
          window.sessionStorage.setItem('publish', 'no');
          setTimeout(() => {
            saveNot();
            // questionAnswers();
          }, 1000);
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info');
          JoinCall(data);
          window.sessionStorage.setItem('publish', 'yes');
          setTimeout(() => {
            saveNot();
            // questionAnswers();
          }, 1000);
        }
      });
    } else {
      Swal.fire({
        title: 'Are you sure you want to join?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: `No`,
        confirmButtonColor: 'var(--theme-bg)', // Set the color for the confirm button
        denyButtonColor: '#A0A2B3'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          JoinCall(data);
        } else if (result.isDenied) {
          /* empty */
        }
      });
    }
  };

  const updateCallTime = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const callID = urlParams.get('callID') ?? ''; // Use '' as default value if null
      const updateCallTime = await apiService.updateCallTime(callID);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  // const appointmentInfo = async () => {
  //   try {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const meetingID = urlParams.get('meetingID') ?? ''; // Use '' as default value if null
  //     const appointmentInfo = await apiService.appointmentInfo(meetingID);
  //     if (appointmentInfo.success === true) {
  //       setReason(true);
  //     }
  //     setAppointmentDetails(appointmentInfo.data.description);
  //     console.log('appointmentInfo', appointmentInfo);
  //   } catch (error) {
  //     console.error('Error fetching Orders:', error);
  //   }
  // };

  const endCallTime = async (data: any) => {
    try {
      const endCallTime = await apiService.endCallTime(data);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const JoinCall = async (data: any) => {
    try {
      const JoinCall = await apiService.JoinCallUpdate(data);

      const urlParams = new URLSearchParams(window.location.search);
      const topic = urlParams.get('topic') ?? ''; // Use '' as default value if null
      const sessionKey = urlParams.get('sessionKey') ?? ''; // Use '' as default value if null
      const userRole = urlParams.get('user_role') ?? ''; // Use '' as default value if null
      const callID = urlParams.get('callID') ?? ''; // Use '' as default value if null
      let meetingID = urlParams.get('meetingID') ?? ''; // Use '' as default value if null
      if (meetingID === '') {
        meetingID = topic;
      }
      const meetID = urlParams.get('meetID') ?? ''; // Use '' as default value if null
      const baseUrl = urlParams.get('baseUrl') ?? ''; // Use '' as default value if null
      // const type = urlParams.get('type') ?? '';
      const type = 'appointment';
      const doctorID = urlParams.get('doctorID') ?? ''; // Use '' as default value if null
      const name = urlParams.get('name') ?? ''; // Use '' as default value if null
      const APPID = urlParams.get('APPID') ?? ''; // Use '' as default value if null
      const Production = urlParams.get('Production') ?? ''; // Use '' as default value if null
      setTimeout(() => {
        window.location.href = `${ProjectURL}/doctor?topic=${data.id}&sessionKey=${sessionKey}&user_role=${userRole}&callID=${data.id}&meetingID=${meetingID}&meetID=${meetID}&baseUrl=${baseUrl}&type=${type}&doctorID=${doctorID}&name=${name}&APPID=${APPID}&Production=${Production}`;
      }, 1000);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };
  const [showQuestionnaire, setQuestionnaire] = useState(true);
  const [Reason, setReason] = useState(true);
  const [patProfile, setPatProfile] = useState(true);
  const [chat, setChatsHide] = useState(true);
  const [notesHide, setNotesHide] = useState(true);
  const [prescriptionHide, setPrescriptionHide] = useState(true);
  const [icdCodeHide, setIcdCodeHide] = useState(true);
  const [AbsenceFormHide, setAbsenceFormHide] = useState(true);
  const [specialtyFormHide, setSpecialtyFormHide] = useState(true);
  const [LabOrdersHide, setLabOrdersHide] = useState(true);
  const [WaitingRoomHide, setWaitingRoomHide] = useState(true);
  const [CallForwardHide, setCallForwardHide] = useState(true);



  const [drugValue, drugSelect] = useState('');

  const [nose, runningNose] = useState('');
  const [reasonForVisit, setReasonForVisit] = useState('');
  const [symptoms, setSymptoms] = useState('');
  const [symptomDuration, setSymptomDuration] = useState('');
  const [report, setReport] = useState('');

  const questionSubmit = async (e: any) => {
    // e.preventDefault();
    // const User_Details = await apiService.viewUser();
    // try {
    //   const SelectData = await apiService.savePrecall(
    //     editableAnswers,
    //     nose,
    //     reasonForVisit,
    //     symptoms,
    //     symptomDuration,
    //     report
    //   );
    //   setSelectValue(SelectData.data);
    // } catch (error) {
    //   console.error('Error fetching Orders:', error);
    // }
  };

  const [absenceData, setAbsenceData] = useState({
    from_date: '',
    notes: '',
    to_date: '',
    resume_date: ''
  });

  const handleAbsence = (event: any) => {
    const { name, value } = event.target;
    setAbsenceData({
      ...absenceData,
      [name]: value
    });
  };

  const handlePhysician = (event: any) => {
    physicianValue(event.target.value);
  };

  const handleTextareaChange = (event: any) => {
    setTextareaValue(event.target.value);
  };

  const [physicianNotes, physicianNotesValue] = useState('');

  const handlePhysicianNotes = (event: any) => {
    physicianNotesValue(event.target.value);
  };

  const getTime = (timestamp: any) => {
    const date = new Date(timestamp);
    const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    const dateString = date.toLocaleDateString('en-US');
    return `${dateString} ${timeString}`;
  };

  const historyS = useHistory();

  const savePublish = () => {
    Swal.fire({
      title: 'What would you prefer to do with the consultation notes?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Save',
      denyButtonText: `Publish`,
      confirmButtonColor: '#A0A2B3', // Set the color for the confirm button
      denyButtonColor: 'var(--theme-bg)',
      allowOutsideClick: false
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setIsLoading(true);
        window.sessionStorage.setItem('publish', 'no');
        await apiService.ZoomRecording();
        setTimeout(() => {
          saveNot();
          // questionAnswers();
        }, 1000);
      } else if (result.isDenied) {
        setIsLoading(true);
        window.sessionStorage.setItem('publish', 'yes');
        await apiService.ZoomRecording();
        setTimeout(() => {
          saveNot();
          // questionAnswers();
        }, 1000);
      }
    });
  };



  useEffect(() => {
    if (!window.sessionStorage.getItem('popup')) {
      window.sessionStorage.setItem('popup', 'no');
    }
    const CallID = async () => {
      try {
        const CallIDDetails = await apiService.GetCallID();
        if (CallIDDetails.success === true) {
          window.sessionStorage.setItem('popup', 'yes');
          await apiService.JoinCallUpdate(UserAllDetails.data.callData);
          const urlParams = new URLSearchParams(window.location.search);
          const topic = urlParams.get('topic') ?? ''; // Use '' as default value if null
          const sessionKey = urlParams.get('sessionKey') ?? ''; // Use '' as default value if null
          const userRole = urlParams.get('user_role') ?? ''; // Use '' as default value if null
          const callID = urlParams.get('callID') ?? ''; // Use '' as default value if null
          const meetingID = urlParams.get('meetingID') ?? ''; // Use '' as default value if null
          const meetID = urlParams.get('meetID') ?? ''; // Use '' as default value if null
          const baseUrl = urlParams.get('baseUrl') ?? ''; // Use '' as default value if null
          // setTimeout(() => {
          //   window.location.reload();
          //   // window.location.href = `http://localhost:3000/doctor?topic=${topic}&sessionKey=${sessionKey}&user_role=${userRole}&callID=${CallIDDetails.data.call_detail_id}&meetingID=${meetingID}&meetID=${meetID}&baseUrl=${baseUrl}`;
          // }, 1000);
        } else {
          console.log('');
        }
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };

    const interval = setInterval(async () => {
      if (window.sessionStorage.getItem('popup') === 'no') {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type') ?? ''; // Use '' as default value if null
        if (type !== 'waitingroom') {
          CallID();
        } else {
          // const urlParams = new URLSearchParams(window.location.search);
          // const topic = urlParams.get('topic') ?? ''; // Use '' as default value if null
          // console.log('topic', topic);
          // if(topic !== '')
          // {
          //   const User_Details = await apiService.viewUserNew(topic);
          // }
        }
      }
    }, 20000); // Fetch every 5 seconds

    return () => clearInterval(interval); // Cleanup function to clear interval when component unmounts
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const BASEURL = urlParams.get('baseUrl') ?? '';
  const typeRoom = urlParams.get('type') ?? ''; // Use '' as default value if null

  const handleChangeQuestion = (e: React.ChangeEvent<HTMLInputElement>, subQuestion: any, question: any,editableAnswers:any) => {
    const { id } = subQuestion; // Sub-question ID
    const newValue = e.target.value; // New answer value
  
   const editData=(prevAnswers: any) => {
      const updatedAnswers = { ...prevAnswers, [id]: newValue };
      // Convert the state object to an array format for structured data
      const answersArray = Object.entries(updatedAnswers).map(([key, value]) => ({
        id: key,
        questionName: subQuestion.name,
        answer: value,
      }));
  
      console.log(answersArray, "Answers as Array");
      return updatedAnswers;
    };    
    // Update the `editableAnswers` state with the new value
    setEditableAnswers(editData)
  };

  
  // const handleChangeQuestion = (e: any, subQuestion: any, question: any, editableAnswers: any) => {
  //   const { id } = subQuestion;
  //   const newValue = e.target.value;
  // console.log(id, newValue, 'akil');
  
  //   console.log(newValue, 'newValue');
  //     setEditableAnswers((prevAnswers: any) => ({
  //     ...prevAnswers, // Keep previous answers      
  //     [id]: newValue, // Update the specific subQuestion's answer
  //   }));
  // };
  
  // const handleChangeQuestion = (e: any, subQuestion: any, question: any, editableAnswers: any) => {
  //   // const newEditableAnswers = {
  //   //   ...editableAnswers,
  //   //   answer: e.target.value,
  //   //   id: subQuestion.id,
  //   //   type_id: question.id
  //   // };
  //   // console.log(newEditableAnswers);

  //   // // Call the debounced function passing the newEditableAnswers object
  //   // debouncedFunction(newEditableAnswers)
    
  // };
  useEffect(() => {
    console.log(editableAnswers, 'Updated editableAnswers');
   sessionStorage.setItem('editableAnswers',editableAnswers)
  }, [editableAnswers,questions]);


  // Define a debounced version of your function
  const [LOGO, setLOGO] = useState('');
  const [ProjectURL, setProjectURL] = useState('');
  const hospitalLogo = async () => {
    try {
      const urlParameter = new URLSearchParams(window.location.search);
      const doctorID = urlParams.get('doctorID') || urlParams.get('DoctorID') || '';
      const User_Details = await apiService.docViewUser(doctorID);
      userDetails(User_Details.data.DoctorProfile);
      const logo = await apiService.hosLogo(User_Details.data.User.hospital_id);
      setLOGO(logo.data[0]);
      setProjectURL(logo.zoom_url);
      // window.sessionStorage.setItem('ProjectUrl', logo.zoom_url);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };

  const transformData = (data: any) => {
    return data?.map((item: any) => ({
      id: item.id,
      name: item.icd_code
    }));
  };
  const urlParams3 = new URLSearchParams(window.location.search);
  const Production = urlParams3.get('Production');

  const items = transformData(value);

  const handleOnSearch = (string: any, results: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result: any) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item: any) => {
    // the item selected
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log('Focused');
  };

  const formatResult = (item: any) => {
    return <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>;
  };

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const previousChatsLengthRef = useRef<number>(chats.length);
  
  useEffect(() => {
    // Only trigger if the length of chats has increased
    if (chats.length > previousChatsLengthRef.current) {
      setTimeout(() => {
        if (
          messageContainerRef.current &&
          messageContainerRef.current.scrollHeight > messageContainerRef.current.clientHeight
        ) {
          const element = messageContainerRef.current;
          element.scrollTop = element.scrollHeight;
        }
      }, 100); // Adjust timeout as needed
    }
  
    // Update the previous chats length to the current length
    previousChatsLengthRef.current = chats.length;
  }, [chats]);
  

  function formatTime(dateString: any) {
    const date = new Date(Date.parse(dateString));
  
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12;
    
    return `${hours}:${minutes} ${ampm}`;
  }
  
  
  const [isInVBMode, setIsInVBMode] = useState(false);
  
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [showPipModal, setpipShowModal] = useState(false);

  const handleOpen = (url: any) => {
    setFileUrl(url);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setFileUrl(''); // Reset the URL when closing
  };

  const handlePipModal = () => {
    setpipShowModal(true);
  };
  const handlePipClose = () => {
    setpipShowModal(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjust this value to show more or fewer items per page

  // Calculate the indices for the items to be displayed on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total number of pages
  const totalPages = Math.ceil(history.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [isChatVisible, setIsChatVisible] = useState(false);
  const toggleChatVisibility = () => {
    setIsChatVisible((prevState) => !prevState);
  };

  const reloadPage = () => {
    historyS.go(0); // This reloads the current route without a full page refresh
  };
  const isDocumentPictureInPictureSupported = () => 'documentPictureInPicture' in window;

  let pipWindow: any = null;
  let originalParent: any = null;
  let originalNextSibling: any = null;
  
  const startPictureInPicture = async () => {
    if (
      isDocumentPictureInPictureSupported() &&
      window.documentPictureInPicture &&
      !window.documentPictureInPicture.window
    ) {
      try {
        pipWindow = await window.documentPictureInPicture.requestWindow({
          height: 512,
          width: 512,
        });
  
        const thisDocument = window.document;
        const pipDocument = pipWindow.document;
  
        // Loader element for the PiP window
        const loader = pipDocument.createElement('div');
        loader.classList.add('loader');
        pipDocument.body.appendChild(loader);
  
        const loaderStyle = pipDocument.createElement('style');
        loaderStyle.textContent = `
          .loader {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 50%;
            left: 50%;
          }
        `;
        pipDocument.head.appendChild(loaderStyle);
  
        // Copy stylesheets to the PiP window
        Array.from(thisDocument.styleSheets).forEach((styleSheet) => {
          try {
            if (styleSheet.href) {
              const linkElement = pipDocument.createElement('link');
              linkElement.rel = 'stylesheet';
              linkElement.href = styleSheet.href;
              pipDocument.head.appendChild(linkElement);
            } else if (styleSheet.ownerNode) {
              const styleElement = pipDocument.createElement('style');
              styleElement.textContent = styleSheet.ownerNode.textContent;
              pipDocument.head.appendChild(styleElement);
            }
          } catch (error) {
            console.error('Error copying stylesheet:', error);
          }
        });
  
        // Move video container to PiP window after 2 seconds
        setTimeout(() => {
          const videoContainer = thisDocument.querySelector('.video-player-container');
          if (videoContainer) {
            originalParent = videoContainer.parentNode;
            originalNextSibling = videoContainer.nextSibling;
  
            pipDocument.body.appendChild(videoContainer);
          } else {
            console.error('No element found with the selector .video-player-container');
            return;
          }
  
          // Hide the loader
          loader.style.display = 'none';
  
          const messageElement = thisDocument.createElement('p');
          messageElement.classList.add('text-center');
          messageElement.id = 'msg-moved-to-pip';
          messageElement.textContent = 'Video has been moved to picture-in-picture window';
          thisDocument.body.appendChild(messageElement);
        }, 2000);
  
        pipWindow.addEventListener('pagehide', () => {
          const videoContainer = pipDocument.querySelector('.video-player-container');
          if (videoContainer && originalParent) {
            originalParent.insertBefore(videoContainer, originalNextSibling);
            originalParent = null;
            originalNextSibling = null;
            thisDocument.querySelector('p#msg-moved-to-pip')?.remove();
          } else {
            console.error('No element found with the selector .video-player-container in PiP window');
          }
          pipWindow = null;
          setTimeout(() => {
            toggleChatVisibility();
          }, 2000);
          setTimeout(() => {
            toggleChatVisibility();
          }, 3000);
        });
      } catch (error) {
        console.error('Error starting Picture-in-Picture:', error);
      }
    } else {
      console.error('Document PiP is not supported, or a PiP window is already open');
    }
  };
  
  const closePictureInPicture = () => {
    if (pipWindow?.close) {
      pipWindow.close();
      pipWindow = null;
    }
  };
  
  let pipTimeout: any;

const startPictureInPictureWithDelay = async () => {
  clearTimeout(pipTimeout);
  pipTimeout = setTimeout(async () => {
    try {
      await startPictureInPicture();
    } catch (error) {
      console.error("Failed to start PiP:", error);
    }
  }, 100);
};

const closeAndResetPiP = () => {
  clearTimeout(pipTimeout);
  try {
    closePictureInPicture();
    handlePipModal();
  } catch (error) {
    console.error("Failed to close PiP:", error);
  }
};

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') {
    startPictureInPictureWithDelay();
  } else if (document.visibilityState === 'visible') {
    closeAndResetPiP();
  }
});

window.addEventListener('blur', () => {
  startPictureInPictureWithDelay();
});

window.addEventListener('focus', () => {
  closeAndResetPiP();
});
const openFullscreen = () => {
  const element = document.documentElement as HTMLElement & {
    mozRequestFullScreen?: () => void;
    webkitRequestFullscreen?: () => void;
    msRequestFullscreen?: () => void;
  };

  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

  
const closeFullscreen = () => {
  const documentWithFullScreen = document as Document & {
    mozCancelFullScreen?: () => void;
    webkitExitFullscreen?: () => void;
    msExitFullscreen?: () => void;
  };

  if (documentWithFullScreen.exitFullscreen) {
    documentWithFullScreen.exitFullscreen();
  } else if (documentWithFullScreen.mozCancelFullScreen) { // Firefox
    documentWithFullScreen.mozCancelFullScreen();
  } else if (documentWithFullScreen.webkitExitFullscreen) { // Chrome, Safari, and Opera
    documentWithFullScreen.webkitExitFullscreen();
  } else if (documentWithFullScreen.msExitFullscreen) { // IE/Edge
    documentWithFullScreen.msExitFullscreen();
  }
};


const hospitalName = window.sessionStorage.getItem('hospital_name');
  ////voice 

  const [practitionerNotes, setPractitionerNotes] = useState('');
  const [followUpNotes, setFollowUpNotes] = useState('');
const [isListening, setIsListening] = useState(false);
const [currentField, setCurrentField] = useState('');
const recognitionRef = useRef<SpeechRecognition | null>(null);
const previousTranscriptRef = useRef('');

useEffect(() => {
  if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;

    recognitionRef.current.onresult = (event: SpeechRecognitionEvent) => {
      let finalTranscript = '';
      let interimTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript;
        } else {
          interimTranscript += event.results[i][0].transcript;
        }
      }

      const fullTranscript = finalTranscript || interimTranscript;
      
      if (fullTranscript !== previousTranscriptRef.current) {
        const newWords = fullTranscript.slice(previousTranscriptRef.current.length).trim();
        
        if (currentField === 'practitioner') {
          setPractitionerNotes(prevNotes => prevNotes + (prevNotes ? ' ' : '') + newWords);
        } else if (currentField === 'followUp') {
          setFollowUpNotes(prevNotes => prevNotes + (prevNotes ? ' ' : '') + newWords);
        }

        previousTranscriptRef.current = fullTranscript;
      }
    };

    recognitionRef.current.onerror = (event: SpeechRecognitionErrorEvent) => {
      console.error('Speech recognition error', event.error);
      setIsListening(false);
    };

    recognitionRef.current.onend = () => {
      setIsListening(false);
    };
  }

  return () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };
}, [currentField]);

const toggleListening = (field: string) => {
  if (isListening && currentField === field) {
    recognitionRef.current?.stop();
    setIsListening(false);
    setCurrentField('');
    previousTranscriptRef.current = '';
  } else {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current.start();
      setIsListening(true);
      setCurrentField(field);
      previousTranscriptRef.current = '';
    } else {
      console.log('Speech recognition is not supported in this browser.');
    }
  }
};
  
  return (
    <div className="main-body" style={{backgroundColor:'#f6f6f6'}}>
       {isLoading && <LoadingLayer content={loadingText} />}
      <nav className="navbar navbar-expand-lg navbar-light custom-body-bg shadow">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={LOGO} crossOrigin="anonymous" className="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
              <li className="nav-item" onClick={handleLeaveRoomClick} style={{ cursor: 'pointer' }}>

                Leave Room

              </li>
              <li className="nav-item">
                {user ? (
                  <a className="nav-link" href="#">
                    <div className="user-profile">
                      <img crossOrigin="anonymous" src={user.profile_picture} className="rounded-circle" />
                      <div className="profile">
                        <h4>{user.name ? user.name : user?.display_name ?? user?.display_name}</h4>
                        {/* <p>Profile</p> */}
                      </div>
                    </div>
                  </a>
                ) : (
                  <p>...</p>
                )}{' '}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {alert && (
        <label className="custom-label">
          {alertContent}
        </label>
      )}



      <div className="container-fluid education-details-page">
      <div className="row" style={{ display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '86vh',
  paddingTop:'15px'}}>
             
                  <div
            className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 shadow-sm bg-white"
            style={{ height: '578px' }}
          >
           
              {window.sessionStorage.getItem('loading') === 'true' && (
      <div style={{
        display: 'flex',
        flexDirection: 'column',  
        justifyContent: 'center',   
        alignItems: 'center',       
        height: '100%'            
      }}>
          <img src="/icon/load.gif" width="100px" alt="Loading..." style={{ marginBottom: '16px' }} />
      <h6>Loading video</h6>
      </div>
  )}
         {window.sessionStorage.getItem('loading') === 'false' &&   
          <div className="video-main-tab doctor-section-video position-relative">
              <div>
                <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
                <div
                  className={classnames('video-container video-player-container', {
                    'video-container-in-sharing': isRecieveSharing
                  })}
                >
                    <canvas
                    className="video-canvas"
                    id="video-canvas"
                    // width="630"
                    // height="450"
                    style={isChatVisible ? { width: '100%', height: '100%' } : { width: '100%', height: '482px' }}
                    ref={videoRef}
                  />
                  <AvatarActionContext.Provider value={avatarActionState}>
                    <ul className="avatar-list">
               
                  
                      {visibleParticipants.map((user, index) => {
                        if (index > videoLayout.length - 1) {
                          return null;
                        }

                        const dimension = videoLayout[index];
                        const { width, height, x, y } = dimension;
                        const { height: canvasHeight } = canvasDimension;
                        return (
                          <Avatar
                            participant={user}
                            key={user.userId}
                            isActive={activeVideo === user.userId}
                            networkQuality={networkQuality[`${user.userId}`]}
                            style={{
                              width: `${width}px`,
                              height: `${height}px`,
                              top: `${canvasHeight - y - height}px`,
                              left: `${x}px`
                            }}
                          />
                        );
                      })}
                    </ul>
                    <RemoteCameraControlPanel />
                  </AvatarActionContext.Provider>
                </div>
                <VideoFooter
                          className="video-operations p-5"
                          sharing
                          selfShareCanvas={shareViewRef.current?.selfShareRef}
                          data={savePublish}
                        />

              </div>
            </div>}
             {/* <button onClick={startPictureInPicture}>Start PiP</button>  */}

          </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 shadow-sm bg-white">
                  <div className="d-flex video-tab-details-section">
                        <nav className="custon-video-tab">
                          <div className="nav nav-tabs mb-4 d-flex flex-column gap-2" id="nav-tab" role="tablist">

                             {HideAll && (
                             
                             <button
                             className="nav-link p-2 rounded-0 border skeleton-button" // Added skeleton class
                             id="nav-homee-tab"
                             data-bs-toggle="tab"
                             data-bs-target="#nav-homee"
                             data-toggle="tooltip"
                             data-placement="right"
                             title="Reason"
                             type="button"
                             role="tab"
                             aria-controls="nav-homee"
                             aria-selected="true"
                           >
                             <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                               <div className="rounded-circle doctor-profile skeleton-circle">
                                 {/* Skeleton placeholder instead of images */}
                                 <div className="skeleton-icon desktio-icon" />
                                 <div className="skeleton-icon desktio-mobile" />
                               </div>
                             </div>
                           </button>
                           
                    )}

                            {!HideAll && !HideWaiting && Reason && (
                              <button
                                className="nav-link p-2 rounded-0 border"
                                id="nav-homee-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-homee"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Reason"
                                type="button"
                                role="tab"
                                aria-controls="nav-homee"
                                aria-selected="true"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    <img className="desktio-icon" src="/icon/icon-cl-1.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-1.png" />
                                  </div>
                                </div>
                              </button>
                            )}

                            {!HideAll&&!HideWaiting && showQuestionnaire && (
                              <button
                                className="nav-link p-2 rounded-0 border"
                                id="nav-home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-home"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Questionnaire"
                                type="button"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    {/* <img className="desktio-icon" src="/icon/icon-cl-1.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-1.png" /> */}
                                    <svg width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6747 0.320801H7.32531C7.18519 0.320801 7.05082 0.376461 6.95174 0.475537C6.85267 0.574613 6.79701 0.708988 6.79701 0.849103V2.6242H5.83021C5.46769 2.62459 5.11714 2.75397 4.84128 2.98918C4.56542 3.22438 4.38226 3.55007 4.32455 3.90797H0.959271C0.819157 3.90797 0.684781 3.96363 0.585705 4.06271C0.48663 4.16178 0.430969 4.29616 0.430969 4.43627V25.151C0.430969 25.2911 0.48663 25.4255 0.585705 25.5246C0.684781 25.6236 0.819157 25.6793 0.959271 25.6793H17.0408C17.1809 25.6793 17.3153 25.6236 17.4143 25.5246C17.5134 25.4255 17.5691 25.2911 17.5691 25.151V4.43627C17.5691 4.29616 17.5134 4.16178 17.4143 4.06271C17.3153 3.96363 17.1809 3.90797 17.0408 3.90797H13.6808C13.6209 3.55009 13.4364 3.22491 13.1599 2.98997C12.8834 2.75503 12.5327 2.62546 12.1698 2.6242H11.203V0.849103C11.203 0.708988 11.1474 0.574613 11.0483 0.475537C10.9492 0.376461 10.8149 0.320801 10.6747 0.320801ZM16.4861 24.6227H1.48757V4.96457H4.29814V5.49288C4.29814 5.63299 4.3538 5.76737 4.45288 5.86644C4.55195 5.96552 4.68633 6.02118 4.82644 6.02118H13.1736C13.3137 6.02118 13.4481 5.96552 13.5472 5.86644C13.6463 5.76737 13.7019 5.63299 13.7019 5.49288V4.96457H16.5125L16.4861 24.6227ZM12.1698 3.6808C12.2327 3.6808 12.295 3.69327 12.353 3.71749C12.4111 3.74172 12.4637 3.77721 12.5079 3.82193C12.5521 3.86664 12.5871 3.91968 12.6106 3.97798C12.6342 4.03628 12.646 4.09867 12.6453 4.16156V4.98571H5.35474V4.16156C5.35404 4.09867 5.36583 4.03628 5.38941 3.97798C5.41299 3.91968 5.4479 3.86664 5.49212 3.82193C5.53635 3.77721 5.589 3.74172 5.64703 3.71749C5.70506 3.69327 5.76733 3.6808 5.83021 3.6808H7.32531C7.46542 3.6808 7.5998 3.62514 7.69888 3.52607C7.79795 3.42699 7.85361 3.29261 7.85361 3.1525V1.3774H10.1464V3.1525C10.1464 3.29261 10.2021 3.42699 10.3012 3.52607C10.4003 3.62514 10.5346 3.6808 10.6747 3.6808H12.1698Z" fill="var(--theme-bg)"/>
</svg>

                                  </div>
                                </div>
                              </button>
                            )}

                            {!HideAll&&!HideWaiting && patProfile && (
                              <button
                                className="nav-link p-2 rounded-0 border"
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-profile"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Patient Chart"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3485 15.8185C11.7881 15.7025 13.2344 15.7025 14.6741 15.8185C15.4581 15.8642 16.2383 15.9623 17.0099 16.1121C18.6796 16.4502 19.7697 17.0018 20.2368 17.8915C20.5877 18.5858 20.5877 19.4124 20.2368 20.1068C19.7697 20.9964 18.7229 21.5836 16.9926 21.8861C16.2216 22.0416 15.4412 22.1427 14.6568 22.1886C13.9301 22.2687 13.2034 22.2687 12.468 22.2687H11.1444C10.8675 22.2331 10.5994 22.2153 10.3398 22.2153C9.55538 22.1749 8.77477 22.0768 8.00397 21.9217C6.33428 21.6014 5.24422 21.032 4.77705 20.1423C4.59671 19.7977 4.50163 19.4127 4.5001 19.0213C4.49646 18.6276 4.58866 18.2391 4.7684 17.8915C5.22692 17.0018 6.31698 16.4235 8.00397 16.1121C8.77816 15.9601 9.56144 15.862 10.3485 15.8185ZM12.5026 2.26868C15.4028 2.26868 17.7539 4.6865 17.7539 7.66903C17.7539 10.6516 15.4028 13.0694 12.5026 13.0694C9.60241 13.0694 7.25131 10.6516 7.25131 7.66903C7.25131 4.6865 9.60241 2.26868 12.5026 2.26868Z" fill="var(--theme-bg)"/>
</svg>
                                    {/* <img className="desktio-mobile" src="/icon/icon-w-2.png" /> */}
                                  </div>
                                </div>
                              </button>
                            )}
                            {!HideAll&&!HideWaiting && chat && (
                              <button
                                className={`nav-link ${AppActiveTab} p-2 rounded-0 border`}
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-profile2"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Chat"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    {/* <img className="desktio-icon" src="/icon/icon-cl-4.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-4.png" /> */}
                                    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.72222 14.9284C8.09032 14.9284 6.55264 14.6376 5.20212 14.1244C3.84036 15.1799 2.16962 15.823 0.394661 16.0074C0.381829 16.0087 0.368935 16.0094 0.356033 16.0094C0.19388 16.0094 0.0473958 15.902 0.00807291 15.7488C-0.0344618 15.5785 0.0986978 15.4737 0.230555 15.3487C0.882335 14.7279 1.67248 14.2399 1.98151 12.1543C0.738498 10.974 0 9.50079 0 7.90165C0 4.02046 4.35317 0.874878 9.72222 0.874878C15.0913 0.874878 19.4444 4.02041 19.4444 7.90165C19.4444 11.7856 15.0913 14.9284 9.72222 14.9284ZM24.7859 19.5364C24.1807 18.972 23.4469 18.5283 23.16 16.6323C26.1465 13.8562 25.4244 9.88479 21.5249 7.72941C21.5262 7.78676 21.5277 7.84408 21.5277 7.90165C21.5277 13.1683 15.9035 17.2057 9.1904 16.9833C10.845 18.3145 13.2698 19.1543 15.9722 19.1543C17.4875 19.1543 18.9154 18.8899 20.1694 18.4233C21.4339 19.3829 22.9853 19.9676 24.6335 20.1352C24.7978 20.1522 24.9531 20.0503 24.9924 19.9001C25.032 19.7453 24.9083 19.65 24.7859 19.5364Z" fill="var(--theme-bg)"/>
</svg>

                                    {messageCount > 0 && (
                                      <div className="count-number">{messageCount}</div>
                                    )}

                                  </div>
                                </div>
                              </button>
                            )}
                            {!HideAll&&!HideWaiting && notesHide && (
                              <button
                                className="nav-link p-2 rounded-0 border"
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-profile3"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Notes"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    {/* <img className="desktio-icon" src="/icon/icon-cl-5.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-5.png" /> */}
                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.75 4.7124L5.39063 13.0718C5.23438 13.228 5.15625 13.3843 5.15625 13.5405L4.6875 18.6968C4.6875 18.9312 4.76563 19.1655 4.92188 19.3218C5.07813 19.478 5.23438 19.5562 5.46875 19.5562H5.54687L10.7031 19.0874C10.8594 19.0874 11.0938 19.0093 11.1719 18.853L19.5312 10.4937L13.75 4.7124Z" fill="var(--theme-bg)"/>
<path d="M20.625 9.3999L21.7188 8.30615C22.6563 7.36865 22.5781 5.88428 21.6406 4.94678L19.2969 2.60303C18.8281 2.13428 18.2813 1.8999 17.5781 1.8999C16.9531 1.8999 16.3281 2.13428 15.8594 2.60303L14.8438 3.61865L20.625 9.3999Z" fill="var(--theme-bg)"/>
<path d="M21.875 22.6812H3.125C2.65625 22.6812 2.34375 22.3687 2.34375 21.8999C2.34375 21.4312 2.65625 21.1187 3.125 21.1187H21.875C22.3438 21.1187 22.6562 21.4312 22.6562 21.8999C22.6562 22.3687 22.3438 22.6812 21.875 22.6812Z" fill="var(--theme-bg)"/>
</svg>

                                  </div>
                                </div>
                              </button>
                            )}
                            {/* {LabOrdersHide && (
                            <button
                              className="nav-link p-2 rounded-0 border"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile1"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                {' '}
                                <div className="rounded-circle doctor-profile">
                                  <img className="desktio-icon" src="/icon/icon-cl-3.png" />
                                  <img className="desktio-mobile" src="/icon/icon-w-3.png" />
                                </div>
                              </div>
                            </button>
                          )} */}
                            {!HideAll&&!HideWaiting && icdCodeHide && (
                              <button
                                className="nav-link p-2 rounded-0 border"
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-profile6"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    <img className="desktio-icon" src="/icon/icon-cl-6.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-6.png" />
                                  </div>
                                </div>
                              </button>
                            )}

                            {/* {AbsenceFormHide && (
                            <button
                              className="nav-link p-2 rounded-0 border"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile5"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                <div className="rounded-circle doctor-profile">
                                  <img className="desktio-icon" src="/icon/icon-cl-7.png" />
                                  <img className="desktio-mobile" src="/icon/icon-w-7.png" />
                                </div>
                              </div>
                            </button>
                          )} */}
                            {/* {specialtyFormHide && (
                            <button
                              className="nav-link p-2 rounded-0 border"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile4"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                <div className="rounded-circle doctor-profile">
                                  <img className="desktio-icon" src="/icon/icon-cl-8.png" />
                                  <img className="desktio-mobile" src="/icon/icon-w-8.png" />
                                </div>
                              </div>
                            </button>
                          )} */}

                            {/* {prescriptionHide && (
                            <button
                              className="nav-link p-2 rounded-0 border"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile7"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                <div className="rounded-circle doctor-profile">
                                  <img className="desktio-icon" src="/icon/icon-cl-9.png" />
                                  <img className="desktio-mobile" src="/icon/icon-w-9.png" />
                                </div>
                              </div>
                            </button>
                          )} */}

                            {!HideAll&&WaitingRoomHide && (
                              <button
                                ref={buttonRefWait}
                                className={`nav-link ${WaitActiveTab} p-2 rounded-0 border`}
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Waiting Room"
                                data-bs-target="#nav-profile8"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    {/* <img className="desktio-icon" src="/icon/icon-cl-10.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-10.png" /> */}
                                    <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0183 5.51887C14.076 5.51887 15.0524 3.80189 15.0524 2.41195C15.0524 1.02201 14.1574 0 13.0183 0C11.8792 0 10.9842 1.06289 10.9842 2.41195C10.9842 3.76101 11.9606 5.51887 13.0183 5.51887ZM6.83461 8.25786C6.83461 7.48113 7.20075 6.74528 7.7703 6.2956L5.98028 5.84591C5.98028 5.84591 4.75982 6.99057 4.55641 6.99057C4.353 6.99057 2.92912 5.84591 2.92912 5.84591L0.854326 6.41824C0.325458 6.54088 0 7.07233 0 7.64465V12.4686C0 12.7547 0.203411 13 0.447504 13H6.83461V8.25786ZM4.55641 5.0283C5.37005 5.0283 6.22438 3.67925 6.22438 2.45283C6.22438 1.34906 5.4921 0.449686 4.55641 0.449686C3.62072 0.449686 2.88844 1.34906 2.88844 2.45283C2.88844 3.67925 3.78345 5.0283 4.55641 5.0283ZM21.4802 5.31447C22.2938 5.31447 23.1482 3.96541 23.1482 2.73899C23.1482 1.63522 22.4159 0.735849 21.4802 0.735849C20.5445 0.735849 19.8122 1.63522 19.8122 2.73899C19.7716 3.96541 20.6666 5.31447 21.4802 5.31447ZM18.2663 8.29874C18.2663 7.60377 17.8188 6.99057 17.2086 6.82704L14.5642 6.17296C14.5642 6.17296 13.181 7.72642 13.0183 7.7673C12.8149 7.7673 11.1876 6.17296 11.1876 6.17296L8.78736 6.82704C8.17712 6.99057 7.72962 7.60377 7.72962 8.25786V13H18.2256V8.29874H18.2663ZM25.1416 6.7044L22.8634 6.13208C22.8634 6.13208 21.6429 7.27673 21.4395 7.27673C21.2361 7.27673 19.8122 6.13208 19.8122 6.13208L18.429 6.5C18.8765 6.94969 19.1613 7.56289 19.1613 8.25786V12.9591H25.9552C25.9959 12.8774 25.9959 12.7956 25.9959 12.7138V7.93082C26.0366 7.35849 25.6705 6.82704 25.1416 6.7044Z" fill="var(--theme-bg)"/>
</svg>

                                    <div className="count-number">{WaitingListCount || 0}</div>
                                  </div>
                                </div>
                              </button>
                            )}
                            {!HideAll&&CallForwardHide && (
                              <button
                                ref={buttonRef}
                                className={`nav-link ${ForwardActiveTab} p-2 rounded-0 border`}
                                id="nav-forward-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-forward9"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Call Forwarding"
                                type="button"
                                role="tab"
                                aria-controls="nav-forward"
                                aria-selected="false"
                              >
                                <div className="doctor-details notifications-detail-user d-flex justify-content-center align-items-center">
                                  <div className="rounded-circle doctor-profile">
                                    {/* <img className="desktio-icon" src="/icon/icon-cl-11.png" />
                                    <img className="desktio-mobile" src="/icon/icon-w-11.png" /> */}
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_3330_6751)">
<path d="M13.7372 21C11.8251 20.9328 10.2149 20.3117 8.90655 19.0024C6.59186 16.7194 4.29393 14.4196 2.01278 12.1031C-0.637376 9.41724 -0.670923 5.17024 1.89537 2.55153C2.46566 1.964 3.1869 1.93043 3.77397 2.50117C5.26678 3.9616 6.74281 5.43882 8.20208 6.93283C8.77237 7.50357 8.75559 8.17503 8.20208 8.76256C7.98403 8.99758 7.7492 9.2158 7.53115 9.43403C7.22924 9.7194 7.26278 9.98798 7.53115 10.2566C8.60464 11.3309 9.67812 12.4052 10.7516 13.4796C11.02 13.7482 11.2716 13.7482 11.5399 13.4796C11.7748 13.2446 12.0096 12.9928 12.2612 12.7578C12.8147 12.2374 13.4856 12.2038 14.0224 12.7242C15.5655 14.235 17.0751 15.7626 18.5847 17.3069C19.0711 17.8105 19.0208 18.5323 18.5176 19.0192C17.1757 20.3285 15.5487 20.9328 13.754 20.9832L13.7372 21Z" fill="var(--theme-bg)"/>
<path d="M14.123 3.458C14.8442 2.73618 15.4816 2.08151 16.119 1.44362C16.5048 1.05752 16.8906 0.654647 17.2931 0.268556C17.6789 -0.100749 18.0815 -0.100749 18.4505 0.268556C19.2053 1.00716 19.9601 1.76256 20.6981 2.51796C21.0839 2.92083 21.0671 3.32371 20.6478 3.76016C19.7756 4.64985 18.8866 5.52275 18.0144 6.39565C17.8802 6.52995 17.7292 6.63067 17.4776 6.83211C17.8299 7.13426 18.0982 7.35249 18.3498 7.5875C18.6182 7.8393 18.7188 8.14146 18.5847 8.49398C18.4337 8.84649 18.1653 8.99757 17.7963 8.99757C16.1693 8.99757 14.5256 8.99757 12.8986 8.99757C12.3115 8.99757 12.0096 8.67863 12.0096 8.07431C12.0096 6.4628 11.976 4.8345 12.0264 3.22299C12.0264 2.95441 12.2612 2.60189 12.496 2.41724C12.7979 2.18222 13.1502 2.35009 13.4018 2.60189C13.6534 2.85369 13.8714 3.13906 14.1398 3.44122L14.123 3.458Z" fill="var(--theme-bg)"/>
</g>
<defs>
<clipPath id="clip0_3330_6751">
<rect width="21" height="21" fill="white"/>
</clipPath>
</defs>
</svg>

                                  </div>
                                </div>
                              </button>
                            )}
                          </div>
                        </nav>
                        {/* 1 */}
                        <div className="tab-content bg-white rounded p-4 border m-1" id="nav-video-tab-details-section">
                          {showQuestionnaire && (
                            <div
                              className="tab-pane show fade"
                              id="nav-home"
                              role="tabpanel"
                              aria-labelledby="nav-home-tab"
                            >
                              {questions?.map((question: any, index: number) => (
                                <div key={question.id}>
                                  <h4>{question.name}</h4>
                                  <div className="video-tab-record-ditails pe-2">
                                    {question.questions.map((subQuestion: any, subIndex: number) => (
                                      <div key={subQuestion.id} className="form-group mb-3">
                                        <label htmlFor={`answer-${subQuestion.id}`} className="form-label">
                                          {subQuestion.name}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={`answer-${subQuestion.id}`}
                                          placeholder=""
                                          value={editableAnswers[subQuestion.id] ?? subQuestion.answer}
                                          onChange={(e) =>
                                            handleChangeQuestion(e, subQuestion, question, editableAnswers)
                                          }
                                        //   setEditableAnswers(newEditableAnswers);
                                        //   console.log(editableAnswers, 'editableAnswers');
                                        // }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {/* {Reason && (
                            <div
                              className="tab-pane show fade"
                              id="nav-homee"
                              role="tabpanel"
                              aria-labelledby="nav-homee-tab"
                            >
                              <p className="patient-chart">Reason for Appointment</p>
                              <input type="text" className="form-control" value={appointmentDetails} disabled />
                            </div>
                          )} */}

                          {/* 2 */}
                          {patProfile && (
                            <div
                              className="tab-pane fade show"
                              id="nav-profile"
                              role="tabpanel"
                              aria-labelledby="nav-profile-tab"
                            >
                              <h4>Patient Chart</h4>
                              <div className="video-tab-record-ditails pe-2">
                                {userPat ? (
                                  <div className="patient-chart row">
                                    <div className="patient-image col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center">
                                      <img
                                        crossOrigin="anonymous"
                                        src={userPat.profile_picture}
                                        className="rounded-circle"
                                      />
                                    </div>
                                    <div className="patient-content col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                      <p>Name: {userPat.name}</p>
                                      <p>Gender: {userPat.gender}</p>
                                      <p>DOB: {formatDate(userPat.dob)}</p>
                                      <p>Emirates ID:{userPat.ssno}</p>
                                      {/* <p>Height: {PatientUser.height}</p>
                                    <p>Weight: {PatientUser.weight}</p>
                                    <p>BMI: {PatientUser.bmi}</p> */}
                                      {/* <p>Height: 180cm</p>
                                    <p>Weight: 60kg</p>
                                    <p>BMI: 18.5</p> */}

                                      {/* Add more user details as needed */}
                                    </div>
                                    <div className="patient-content col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                      {/* <p>{userPat.address}</p> */}
                                      <p>
                                        {userPat.city}, {userPat.state} - {userPat.postal_code}
                                      </p>
                                      <p>PH: {userPat.phone}</p>
                                    </div>
                                  </div>
                                ) : (
                                  <p>Loading user details...</p>
                                )}{' '}
                                <h5 className="my-3">Consultation History</h5>
                                <div className="patient-show-search visit-history-table">
                                  {/* <div className="row mb-3">
                                <div className="patient-show col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                  <span>Show</span>
                                  <select className="form-control" aria-label="Default select example">
                                    <option selected>1</option>
                                    <option value={1}>2</option>
                                    <option value={2}>3</option>
                                  </select>
                                </div>
                                <div className="patient-search col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6">
                                  <span>Search</span>
                                  <input type="text" className="form-control" id="first-name" />
                                </div>
                              </div> */}
                                  <div>
      <div className="visit-history-table-scroll">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Doctor</th>
              <th>Call Date</th>
              <th>Duration</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item: any, index: number) => (
              <tr key={index}>
                <td>{item.receiver_name}</td>
                <td>{item.call_date}</td>
                <td>{item.call_duration}</td>
                <td className="table-view-btn">
                  <a
                    href={`${Production}patient/down-pdf?id=${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
          <button
            key={pageNumber}
            className={`pagination-btn ${currentPage === pageNumber ? 'active' : ''}`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
                                </div>
                              </div>
                              <div className="col-12 pt-2">
                                {/* <p>
                                <b>Note:</b> you can publish your consultation in the prescription (last) tab
                              </p> */}
                              </div>
                            </div>
                          )}
                          {/* 3 */}
                          {/* {chat && (
                          // <div
                          //   className="tab-pane fade"
                          //   id="nav-profile1"
                          //   role="tabpanel"
                          //   aria-labelledby="nav-profile-tab"
                          // >
                          //   <h4>Lab Orders</h4>
                          //   <div className="video-tab-record-ditails pe-2">
                          //     <div className="row">
                          //       <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          //         <div className="accordion arrow-hide-accordion" id="regularAccordionRobots">
                          //           {clinical.map((clini: any, index: number) => (
                          //             <div className="accordion-item" key={index}>
                          //               <h2 id="regularHeadingFirst" className="accordion-header">
                          //                 <div
                          //                   className="accordion-button"
                          //                   typeof="button"
                          //                   data-bs-toggle="collapse"
                          //                   data-bs-target={`#regularCollapse${index}`}
                          //                   aria-expanded="true"
                          //                   aria-controls={`regularCollapse${index}`}
                          //                 >
                          //                   {clini.name}
                          //                 </div>
                          //               </h2>
                          //               <div
                          //                 id={`regularCollapse${index}`}
                          //                 className="accordion-collapse collapse show"
                          //                 aria-labelledby="regularHeadingFirst"
                          //                 data-bs-parent="#regularAccordionRobots"
                          //               >
                          //                 {clini.OrderSubMaster.map((order: any, i: number) => (
                          //                   <div className="accordion-body pe-0 text-center ps-0" key={i}>
                          //                     <div className="lab-order-list">
                          //                       <div className="laboratory-list">
                          //                         <ul>
                          //                           <li>{order.name}</li>
                          //                         </ul>
                          //                       </div>

                          //                       <div className="row" key={i}>
                          //                         <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          //                           <div
                          //                             className="accordion arrow-hide-accordion"
                          //                             id="regularAccordionRobots2"
                          //                           >
                          //                             {order.OrderPackages?.map((datalist: any, j: number) => (
                          //                               <div className="accordion-item" key={j}>
                          //                                 <h2 id="regularHeadingFirst2" className="accordion-header">
                          //                                   <div
                          //                                     className="accordion-button"
                          //                                     typeof="button"
                          //                                     data-bs-toggle="collapse"
                          //                                     data-bs-target={`#regularCollapse${j + 1}`}
                          //                                     aria-expanded="true"
                          //                                     aria-controls={`regularCollapse${j + 1}`}
                          //                                   >
                          //                                     {datalist.name}
                          //                                   </div>
                          //                                 </h2>
                          //                                 <div
                          //                                   id={`regularCollapse${j + 1}`}
                          //                                   className="accordion-collapse collapse show"
                          //                                   aria-labelledby="regularHeadingFirst2"
                          //                                   data-bs-parent="#regularAccordionRobots2"
                          //                                 >
                          //                                   <div
                          //                                     className="accordion-body pe-0 text-center ps-0"
                          //                                     key={j + 1}
                          //                                   >
                          //                                     <div className="laboratory-factor">
                          //                                       <div className="laboratory-check" key={j + 1}>
                          //                                         <div className="check-title">
                          //                                           <label className="pb-3 border-bottom">
                          //                                             <input
                          //                                               type="checkbox"
                          //                                               className="option-input checkbox"
                          //                                               checked={selectedIds.includes(datalist.id)}
                          //                                               onChange={() =>
                          //                                                 handleCheckboxClick(datalist.id)
                          //                                               }
                          //                                             />
                          //                                             <h3 className="ps-1 mb-0">{datalist.name}</h3>
                          //                                           </label>
                          //                                         </div>

                          //                                         <div className="form-group mb-3 lab-orders my-4">
                          //                                           {datalist.OrderTest?.map((item: any, k: number) => (
                          //                                             <label className="mb-2" key={k}>
                          //                                               <input
                          //                                                 type="checkbox"
                          //                                                 className="option-input checkbox"
                          //                                                 checked={selectedIds.includes(item.id)}
                          //                                                 onChange={() => handleCheckboxClick(item.id)}
                          //                                               />
                          //                                               <span>{item.name}</span>
                          //                                             </label>
                          //                                           ))}
                          //                                         </div>
                          //                                       </div>
                          //                                     </div>
                          //                                   </div>
                          //                                 </div>
                          //                               </div>
                          //                             ))}
                          //                           </div>
                          //                         </div>
                          //                       </div>
                          //                     </div>
                          //                   </div>
                          //                 ))}
                          //               </div>
                          //             </div>
                          //           ))}
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </div>
                          // </div>
                        )} */}
                          {/* 4 */}
                          {chat && (
                            <div
                              className={`tab-pane fade ${AppActiveTab} show`}
                              id="nav-profile2"
                              role="tabpanel"
                              aria-labelledby="nav-profile-tab"
                            >
                              <h4>Chat</h4>
                              <div className="video-tab-record-ditails pe-2">
                                <div className="chat-area chat-area-340">
                                  <div className="chatbox">
                                    <div className="modal-dialog-scrollable">
                                      <div className="modal-content">
                                        <div className="modal-body" ref={messageContainerRef}>
                                          <div className="msg-body">
                                            <ul>
                                              {chats.map((chat: any) => (
                                                <li
                                                  className={chat.sender_id === userId ? 'repaly' : 'sender'}
                                                  key={chat.id}
                                                >
                                                 
                                                  <div className="user-profile-cont">
                                                   
                                                    <img
                                                      src={chat.sender_id === userId ? DoctorImg : PatientImg}
                                                      alt="User Image"
                                                      crossOrigin="anonymous"
                                                      className="user-image"
                                                    />
                                                    {/* <span className="time"> <span style={{marginRight:'168px'}} className={chat.sender_id === userId ? '' : 'd-none'}>from <span className='user-text'>{chat.sender_name}</span></span> {formatTime(chat.created_at)} <span style={{marginLeft:'140px'}} className={chat.sender_id === userId ? 'd-none' : ''}>from <span className='user-text'>{chat.sender_name}</span></span></span> */}
                                                   <div className='d-flex justify-content-between w-full' style={{width: "-webkit-fill-available"}}> 
                                                   {chat.sender_id === userId ? (
  <>
    <span className="time">{formatTime(chat.created_at)}</span>
    <span className="time"><span className='user-text'>Me</span></span>
  </>
) : (
  <>
    <span className="time"><span className='user-text'>{chat.sender_name}</span></span>
    <span className="time">{formatTime(chat.created_at)}</span>
  </>
)}
                                                   </div>
                                                   
                                                    
                                                  </div>
                                                  {chat.message !== '' ? (
                                                    <p>{chat.message}</p>
                                                  ) : (
                                                    <div className="user-attachment">
                                                    <a
                                                      href={chat.attachement}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      onClick={(e) => {
                                                        e.preventDefault(); // Prevent default link action
                                                        handleOpen(chat.attachement); // Open modal with file URL
                                                      }}
                                                    >
                                                      {chat.attachement ? (
                                                        chat.attachement.endsWith('.pdf') ? (
                                                          <img
                                                            src="images/pdf.png"
                                                            alt="PDF Icon"
                                                            className="pdf-icon"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={chat.attachement}
                                                            alt="Chat Attachment"
                                                            crossOrigin="anonymous"
                                                            className="chat_attachment"
                                                          />
                                                        )
                                                      ) : null}
                                                    </a>
                                                    <Modal showModal={showModal} handleClose={handleClose} fileUrl={fileUrl} />
                                                  </div>
                                                  )}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="send-box">
                                        <form onSubmit={handleSubmit}>
  <div className="send-msg-wrap">
    <div className="button-wrapper">
      <span className="label">
        <img className="img-fluid" src="/icon/upload.svg" alt="image title" />
      </span>
      <input
        type="file"
        onChange={handleFileChange}
        name="upload"
        id="upload"
        className="upload-box"
        placeholder="Upload File"
        aria-label="Upload File"
        style={{ pointerEvents: 'auto' }} // Ensure the file input is clickable
      />
    </div>
    <input
      type="text"
      className="form-control"
      value={textInput}
      onChange={handleInputChange}
      aria-label="message…"
      placeholder="Type message…"
      style={{ pointerEvents: 'auto' }} // Ensure the text input is clickable
    />
  </div>
  <button type="submit" disabled={textInput === ''}>
    <img src="/icon/send.png" alt="Send" />
  </button>
</form>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* 5 */}
                          {notesHide && (
                            <div
                              className="tab-pane fade"
                              id="nav-profile3"
                              role="tabpanel"
                              aria-labelledby="nav-profile-tab"
                            >
                              <h4>Notes</h4>
                              <div className="video-tab-record-ditails pe-2">
                                {notes ? (
                                  <div className="speech-to-text-notes">
                                  <div className="note-section">
                                    <label htmlFor="practitionerNotes" className="note-label">
                                      Practitioner Notes (patient will not see these notes)
                                    </label>
                                    <div className="textarea-container">
                                      <textarea
                                        id="practitionerNotes"
                                        value={practitionerNotes}
                                        onChange={(e) => setPractitionerNotes(e.target.value)}
                                        className="note-textarea"
                                        rows={4}
                                        cols={50}
                                      />
                                      <div
                                        onClick={() => toggleListening('practitioner')}
                                        className={`speech-button ${isListening && currentField === 'practitioner' ? 'listening' : ''}`}
                                      >
                                        {isListening && currentField === 'practitioner' ?  <img src="/icon/mic-fill.svg" alt="Microphone" className="mic-icon" /> : <img src="/icon/mic.svg" alt="Microphone" className="mic-icon" />} 
                                      </div>
                                    </div>
                                  </div>
                            
                                  <div className="note-section">
                                    <label htmlFor="followUpNotes" className="note-label">
                                      Follow Up Notes (patient will see these notes)
                                    </label>
                                    <div className="textarea-container">
                                      <textarea
                                        id="followUpNotes"
                                        value={followUpNotes}
                                        onChange={(e) => setFollowUpNotes(e.target.value)}
                                        className="note-textarea"
                                        rows={4}
                                        cols={50}
                                      />
                                      <div
                                        onClick={() => toggleListening('followUp')}
                                        className={`speech-button ${isListening && currentField === 'followUp' ? 'listening' : ''}`}
                                      >
                                        {isListening && currentField === 'followUp' ? <img src="/icon/mic-fill.svg" alt="Microphone" className="mic-icon" /> : <img src="/icon/mic.svg" alt="Microphone" className="mic-icon" />} 
                                      </div>
                                    </div>
                                  </div>
                            
                                  <style>{`
                                    .speech-to-text-notes {
                                      font-family: Arial, sans-serif;
                                      max-width: 600px;
                                      margin: 0 auto;
                                    }
                                    .note-section {
                                      margin-bottom: 20px;
                                    }
                                    .note-label {
                                      display: block;
                                      margin-bottom: 5px;
                                      font-weight: bold;
                                    }
                                    .textarea-container {
                                      display: flex;
                                      align-items: flex-start;
                                    }
                                    .note-textarea {
                                      flex-grow: 1;
                                      padding: 10px;
                                      border: 1px solid #ccc;
                                      border-radius: 4px;
                                      resize: vertical;
                                    }
                                    .speech-button {
                                      margin-left: 10px;
                                      padding: 10px 15px;
                                      background-color: #007bff;
                                      color: white;
                                      border: none;
                                      border-radius: 4px;
                                      cursor: pointer;
                                      transition: background-color 0.3s;
                                    }
                                    .speech-button:hover {
                                      background-color: #0056b3;
                                    }
                                    .speech-button.listening {
                                      background-color: #dc3545;
                                    }
                                    .speech-button.listening:hover {
                                      background-color: #bd2130;
                                    }
                                  `}</style>
                                </div>
                                ) : (
                                  <p>Loading user details...</p>
                                )}{' '}
                              </div>
                            </div>
                          )}
                          {/* 6 */}
                          {/* {specialtyFormHide && (
                          <div
                            className="tab-pane fade"
                            id="nav-profile4"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <h4>Speciality Referral</h4>
                            <div className="video-tab-record-ditails pe-2">
                              <div className="absence-custom-data">
                                <div className="absence-custom-table d-flex flex-wrap">
                                  <div className="absence-table-data border-end border-bottom p-2 border-start border-top">
                                    {user ? <p>{user.name}</p> : <p>Loading user details...</p>}
                                  </div>
                                  <div className="absence-table-data border-end border-bottom p-2 border-top" />
                                  <div className="absence-table-data border-end p-2 border-bottom border-top">
                                    {patient ? <p>DEA Number: {patient.dea_no}</p> : <p>Loading user details...</p>}
                                    {patient ? <p>NPI Number: {patient.npi_no}</p> : <p>Loading user details...</p>}
                                  </div>
                                </div>
                                <div className="absence-custom-table d-flex flex-wrap border-bottom ">
                                  <div className="absence-table-data border-end p-2 border-start ">
                                    <p>Patient Name</p>
                                    {patient ? <p>{patient.name}</p> : <p>Loading user details...</p>}
                                  </div>
                                  <div className="absence-table-data border-end p-2">
                                    <p>Age/Sex: 26</p>
                                  </div>
                                  <div className="absence-table-data border-end p-2">
                                    {patient ? <p>Date: {patient.dob}</p> : <p>Loading user details...</p>}
                                  </div>
                                </div>
                                <div className="row heric-text">
                                  <div className="col-12">
                                    <h4 className="text-center pt-3">Speciality Referral</h4>
                                    {user && <p>{user.name}</p>}
                                  </div>

                                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                                    
                                    <div className="d-flex flex-nowrap align-items-center gap-2">
                                      <span>Dear Dr.</span>
                                      <input
                                        type="text"
                                        value={physician}
                                        onChange={handlePhysician}
                                        className="form-control"
                                        id="note__name_"
                                      />
                                    </div>
                                    
                                  </div>

                                  <div className="col-12 mb-3">
                                    <p>
                                    <a href="#">I am referring patient {patient ? patient.name : ''} </a> who has the following problem
                                      <a href="#">{user ? user.name : ''}</a>I am referring patient {patient ? patient.name : ''}  who has the
                                      following problem
                                    </p>
                                  </div>
                                  <div className="form-group mb-3">
                                    <label htmlFor="First" className="form-label">
                                      who has the following problem
                                    </label>
                                    <textarea
                                      id="w3review2"
                                      name="w3review"
                                      className="form-control"
                                      rows={4}
                                      cols={50}
                                      value={physicianNotes}
                                      onChange={handlePhysicianNotes}
                                    />
                                  </div>
                                </div>
                                <div className="prescription-sign pt-5">
                                  <div className="prescription-sign-right text-end">
                                    {user ? (
                                      <img crossOrigin="anonymous" className="rounded-circle" src={user.signature} />
                                    ) : (
                                      <p>Loading user details...</p>
                                    )}

                                    <h4>Signature,</h4>
                                    <h5>{user ? user.name : ''}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                          {/* 7 */}
                          {/* {AbsenceFormHide && (
                          <div
                            className="tab-pane fade"
                            id="nav-profile5"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <h4>Absence Form</h4>
                            <div className="video-tab-record-ditails pe-2">
                              <div className="absence-custom-data">
                                <div className="absence-custom-table d-flex flex-wrap">
                                  <div className="absence-table-data border-end border-bottom p-2 border-start border-top">
                                    {user ? <p>{user.name}</p> : <p>Loading user details...</p>}
                                  </div>
                                  <div className="absence-table-data border-end border-bottom p-2 border-top" />
                                  <div className="absence-table-data border-end p-2 border-bottom border-top">
                                    {patient ? <p>DEA Number: {patient.dea_no}</p> : <p>Loading user details...</p>}
                                    {patient ? <p>NPI Number: {patient.npi_no}</p> : <p>Loading user details...</p>}
                                  </div>
                                </div>
                                <div className="absence-custom-table d-flex flex-wrap border-bottom ">
                                  <div className="absence-table-data border-end p-2 border-start ">
                                    <p>Patient Name</p>
                                    {patient ? <p>{patient.name}</p> : <p>Loading user details...</p>}
                                  </div>
                                  <div className="absence-table-data border-end p-2">
                                    <p>Age/Sex: 26</p>
                                  </div>
                                  <div className="absence-table-data border-end p-2">
                                    {patient ? <p>Date: {patient.dob}</p> : <p>Loading user details...</p>}
                                  </div>
                                </div>
                                <div className="row heric-text">
                                  <div className="col-12">
                                    <h4 className="text-left pt-3">Absence Form</h4>
                                  </div>

                                  <div className="col-12 mb-3">
                                    <p>
                                      To whomever it may concern, I, {user ? user.name : ''}, an authorized medical
                                      practitioner after carefully examining the case hereby certify that {patient ? patient.name : ''}
                                    </p>
                                  </div>
                                  <div className="form-group mb-3">
                                    <label htmlFor="from_date" className="form-label">
                                      is suffering from
                                    </label>
                                    <input
                                      type="date"
                                      id="from_date"
                                      name="from_date"
                                      className="form-control"
                                      value={absenceData.from_date}
                                      onChange={handleAbsence}
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label htmlFor="notes" className="form-label">
                                      and I consider that a period of absence from duty of few days with effect from
                                    </label>
                                    <input
                                      type="text"
                                      id="notes"
                                      name="notes"
                                      className="form-control"
                                      value={absenceData.notes}
                                      onChange={handleAbsence}
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label htmlFor="to_date" className="form-label">
                                      to
                                    </label>
                                    <input
                                      type="date"
                                      id="to_date"
                                      name="to_date"
                                      className="form-control"
                                      value={absenceData.to_date}
                                      onChange={handleAbsence}
                                    />
                                  </div>

                                  <div className="form-group mb-3">
                                    <label htmlFor="resume_date" className="form-label">
                                      and resume on
                                    </label>
                                    <input
                                      type="date"
                                      id="resume_date"
                                      name="resume_date"
                                      className="form-control"
                                      value={absenceData.resume_date}
                                      onChange={handleAbsence}
                                    />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <p>is absolutely necessary for the restoration of his/her health</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                          {/* 8 */}
                          {!HideWaiting && icdCodeHide && (
                            <div
                              className="tab-pane fade"
                              id="nav-profile6"
                              role="tabpanel"
                              aria-labelledby="nav-profile-tab"
                            >
                              <h4>ICD code</h4>
                              <div className="video-tab-record-ditails pe-2">
                                <div className="icd-code">
                                  <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="First" className="form-label">
                                          ICD- CODE
                                        </label>
                                        <div className="mb-3">
                                          {/* <input
                                            type="text"
                                            onChange={handleChange}
                                            className="form-control"
                                            id="first-name"
                                          /> */}

                                          {/* <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            onChange={handleIcd}
                                          >
                                            {value.map((val: any, index: number) => (
                                              <option key={index} value={val.id}>
                                                {val.icd_code}
                                              </option>
                                            ))}
                                          </select> */}
                                        </div>

                                        <div className="mb-3">
                                          {/* <input
                                            type="text"
                                            onChange={handleChange2}
                                            className="form-control"
                                            id="first-name"
                                          /> */}

                                          {/* <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            onChange={handleIcd2}
                                          >
                                            {value2.map((val: any, index: number) => (
                                              <option key={index} value={val.id}>
                                                {val.icd_code}
                                              </option>
                                            ))}
                                          </select> */}
                                        </div>

                                        <div className="mb-3">
                                          {/* <input
                                            type="text"
                                            onChange={handleChange3}
                                            className="form-control"
                                            id="last-name"
                                          /> */}

                                          {/* <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            onChange={handleIcd3}
                                          >
                                            {value3.map((val: any, index: number) => (
                                              <option key={index} value={val.id}>
                                                {val.icd_code}
                                              </option>
                                            ))}
                                          </select> */}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                      <div className="form-group mb-3">
                                        <label htmlFor="First" className="form-label">
                                          Notes
                                        </label>
                                        <input type="text" className="form-control mb-2" id="first-name" />
                                        <input type="text" className="form-control mb-2" id="first-name" />
                                        <input type="text" className="form-control mb-2" id="first-name" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* 9 */}
                          {/* {prescriptionHide && (
                          <div
                            className="tab-pane fade"
                            id="nav-profile7"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <h4>Prescription</h4>
                            <div className="video-tab-record-ditails pe-2 visit-history-table">
                              <div className="prescription">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="First" className="form-label">
                                        Drug Name
                                      </label>
                                      <input type="text" className="form-control" id="drug-name" onChange={drugList} />
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        value={drugName}
                                        onChange={handleSelectChang}
                                      >
                                        {drugs.map((val: any, index: number) => (
                                          <option key={index} value={val.id}>
                                            {val.reg_no}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="First" className="form-label">
                                        Dosage
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="drug-name"
                                        value={dosage}
                                        onChange={(e) => setDosage(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="First" className="form-label">
                                        Frequency
                                      </label>
                                      <select
                                        className="form-control"
                                        aria-label="Default select example"
                                        onChange={(e) => setFrequency(e.target.value)}
                                      >
                                        <option value="0-0-1">0-0-1</option>
                                        <option value="0-1-0">0-1-0</option>
                                        <option value="1-0-0">1-0-0</option>
                                        <option value="0-0-1">0-0-1</option>
                                        <option value="1-1-0">1-1-0</option>
                                        <option value="0-1-1">0-1-1</option>
                                        <option value="1-1-1">1-1-1</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="First" className="form-label">
                                        Days
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="days-name"
                                        value={days}
                                        onChange={(e) => setDays(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group mb-3">
                                      <label htmlFor="First" className="form-label">
                                        Notes
                                      </label>
                                      <textarea
                                        id="w3review3"
                                        name="w3review"
                                        className="form-control"
                                        rows={4}
                                        cols={50}
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="d-flex gap-4 doctor-popup-btn justify-content-end flex-wrap my-2 text-end">
                                      <a
                                        href="javascript:void(0);"
                                        className="approve-btn border"
                                        onClick={handleAddDrug}
                                      >
                                        Add
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="visit-history-table-scroll table-responsive">
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Drug</th>
                                      <th>Dosage</th>
                                      <th>Frequency</th>
                                      <th>Days</th>
                                      <th>Notes</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {prescriptions.map((prescription, index) => (
                                      <tr key={index}>
                                        <td>{prescription.drug_name}</td>
                                        <td>{prescription.dosage}</td>
                                        <td>{prescription.frequency}</td>
                                        <td>{prescription.notes}</td>
                                        <td>{prescription.days}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )} */}
                          {/* 10 */}
                          {WaitingRoomHide && (
                            <div
                              className={`tab-pane fade ${WaitActiveTab} show`}
                              id="nav-profile8"
                              role="tabpanels"
                              aria-labelledby="nav-profile-tab"
                            >
                              <h4>Waiting Room</h4>
                              <div className="video-tab-record-ditails pe-2 visit-history-table">
                                {WaitingList.map((item: any, index: number) => (
                                  <div key={item} className="prescription border-bottom mb-4 pb-3">
                                    <div className="custom-user pb-3">
                                      <div className="user-image">
                                        <div className="form-group">
                                          <img
                                            className="rounded-circle"
                                            crossOrigin="anonymous"
                                            src={item.fromUser.profile_picture}
                                          />
                                        </div>
                                      </div>
                                      <div className="user-content">
                                        <h5>{item.fromUser.name}</h5>
                                        <p>{item.fromUser.gender}</p>
                                      </div>
                                    </div>
                                    <div className="wating-room-btn-list">
                                      <ul>
                                        <li>
                                          <span className="time-content">Joining Time </span>
                                          <span className="time">{item.callData.start_time} </span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="wating-room-btn-list">
                                      <ul>
                                        <li onClick={() => savePublishAlert(item.callData)} className="btn btn-normal">
                                          Connect
                                        </li>
                                        <li onClick={() => setForward(item.callData)} className="btn btn-normal">
                                          Forward
                                        </li>
                                        {/* <li>
                                        <a href="#" className="btn btn-danger">
                                          Cancel
                                        </a>
                                      </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          {/* 11 */}
                          {CallForwardHide && (
                            <div
                              className={`tab-pane fade ${ForwardActiveTab} show`}
                              id="nav-forward9"
                              role="tabpanels"
                              aria-labelledby="nav-forward-tab"
                            >
                              <h4>Call Forwarding</h4>
                              <div className="call-video-tab-record-ditails pe-2 visit-history-table">
                                {AvailableDoctorsCount.map(
                                  (item: any, index: number) =>
                                    item.user_id !== userId && (
                                      <div key={item.id} className="custom-user py-3 border-bottom">
                                        <div className="user-image">
                                          <div className="form-group">
                                            <img
                                              className="rounded-circle"
                                              width="30px"
                                              crossOrigin="anonymous"
                                              src={item.profile_picture}
                                            />
                                          </div>
                                        </div>
                                        <div className="aaesha-doctor">
                                          <div className="user-content">
                                            <h5>{item.display_name}</h5>
                                          </div>
                                          <div className="user-content">
                                            <p onClick={() => CallForward(item)} className="btn btn-normal">
                                              Forward
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                              <div
                                className="modal fade"
                                id="staticBackdrop"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-body">
                                      <div>
                                        <h2>Consultation Details</h2>
                                      </div>
                                      <div className="d-flex row justify-between justify-center text-center item-c">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                          onClick={() => window.sessionStorage.setItem('publish', 'no')}
                                        >
                                          save
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          data-bs-dismiss="modal"
                                          onClick={() => window.sessionStorage.setItem('publish', 'yes')}
                                        >
                                          publish
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    {/* {!HideAll ? (
                  
                    ) : (
                      <div className="loader-align">
                        <h5> {typeRoom === 'waitingroom' ? 'Loading...' : 'Loading patient details'}</h5>
                        <div style={{ paddingTop: '20px' }} />
                        <div className="loader mt-4" />
                      </div>
                    )} */}
                  </div>
                </div>
      </div>
      {url && hospitalName === 'Ison Healthcare' &&
       <div style={{ position: 'absolute', right:'510px' }}>
      <button 
        onClick={handleCopyLink} 
        style={{
          position: 'absolute',
          top: '-570px', // Adjust to position the button where you want
          right: '10px',
          padding: '10px',
          backgroundColor: 'var(--theme-bg)',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
                    <img src='/icon/copy.svg' crossOrigin="anonymous" alt='copy' width={'25px'} />

      </button>

      {/* {isCopied && (
        <div 
          style={{
            position: 'absolute',
            top: '40px', // Position below the button
            right: '10px',
            backgroundColor: '#28a745',
            color: '#fff',
            padding: '5px',
            borderRadius: '3px',
            fontSize: '0.9rem'
          }}
        >
          Link copied!
        </div>
      )} */}
    </div>
      }
     
      <PipActivateModel/>
      <PipClosedModel showPipModal={showPipModal} handlePipClose={handlePipClose} />
    </div>
  );
};

export default VideoContainer;
